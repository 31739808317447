// @font-face {
//   // font-family: "Haas Grot Disp R";
//   src: url("./fonts/NeueHaasGrotDispRound-55Roman.otf");
//   font-style: normal;
//   font-weight: 500;
// }
// @font-face {
//   // font-family: "Haas Grot Disp R";
//   src: url("./fonts/NeueHaasGrotDispRound-65Medium.otf");
//   font-style: normal;
//   font-weight: 700;
// }
// @font-face {
//   // font-family: "Haas Grot Text R";
//   src: url("./fonts/NeueHaasGrotTextRound-55Roman.otf");
//   font-style: normal;
//   font-weight: 500;
// }
// @font-face {
//   // font-family: "Haas Grot Text R";
//   src: url("./fonts/NeueHaasGrotTextRound-65Medium.otf");
//   font-style: normal;
//   font-weight: 600;
// }
// @font-face {
//   // font-family: "Haas Grot Text R";
//   src: url("./fonts/NeueHaasGrotTextRound-75Bold.otf");
//   font-style: bold;
//   font-weight: 700;
// }

.page-header {
  position: relative;
  padding-top: 40.27%;
  width: 100%;
  background: url("./assets/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.docs-page {
  // font-family: "Haas Grot Disp R", sans-serif;
  margin-top: -10%;
  font-weight: 500;
  line-height: 1;
  color: #000000;
  overflow-x: hidden;
  @media (max-width: 40em) {
    font-size: 75%;
  }
  @media (min-width: 40em) {
    font-size: 87.5%;
  }
  @media (min-width: 60em) {
    font-size: 93.75%;
  }
  @media (min-width: 82em) {
    font-size: 100%;
  }

  .footer-onepager {
    position: relative;
    bottom: 0;
    z-index: 99;
  }

  .carousel {
    position: relative;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }

  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
  }

  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateX(0);
  }

  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0);
    }
  }
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translateX(100%);
  }

  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0);
    }
  }
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translateX(-100%);
  }

  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0);
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
  }

  .carousel-control-prev:hover,
  .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-next {
    right: 0;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }

  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }

  .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }

  .carousel-indicators .active {
    background-color: #fff;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }

  .button,
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    display: inline-block;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    padding: 1.2em 1.6em;
    text-align: center;
    // font-family: inherit;
    letter-spacing: 1px;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }
  .button:hover,
  .button:focus,
  button:hover,
  button:focus,
  input[type="submit"]:hover,
  input[type="submit"]:focus,
  input[type="reset"]:hover,
  input[type="reset"]:focus,
  input[type="button"]:hover,
  input[type="button"]:focus {
    outline: 0;
  }
  .button.main,
  button.main,
  input[type="submit"].main,
  input[type="reset"].main,
  input[type="button"].main {
    background-color: #7070bb;
    color: #ffffff;
    border-color: #7070bb;
  }
  .button.main:hover,
  button.main:hover,
  input[type="submit"].main:hover,
  input[type="reset"].main:hover,
  input[type="button"].main:hover {
    text-decoration: none;
  }
  .button.main:hover,
  .button.main:active,
  .button.main:focus,
  button.main:hover,
  button.main:active,
  button.main:focus,
  input[type="submit"].main:hover,
  input[type="submit"].main:active,
  input[type="submit"].main:focus,
  input[type="reset"].main:hover,
  input[type="reset"].main:active,
  input[type="reset"].main:focus,
  input[type="button"].main:hover,
  input[type="button"].main:active,
  input[type="button"].main:focus {
    background: #00ba83;
    border-color: #00ba83;
  }
  .button.main.pending,
  button.main.pending,
  input[type="submit"].main.pending,
  input[type="reset"].main.pending,
  input[type="button"].main.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #7070bb 40%, #3affc5 50%, #7070bb 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @-webkit-keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.borrow,
  button.borrow,
  input[type="submit"].borrow,
  input[type="reset"].borrow,
  input[type="button"].borrow {
    background-color: #9669ed;
    color: #ffffff;
    border-color: #9669ed;
  }
  .button.borrow:hover,
  button.borrow:hover,
  input[type="submit"].borrow:hover,
  input[type="reset"].borrow:hover,
  input[type="button"].borrow:hover {
    text-decoration: none;
  }
  .button.borrow:hover,
  .button.borrow:active,
  .button.borrow:focus,
  button.borrow:hover,
  button.borrow:active,
  button.borrow:focus,
  input[type="submit"].borrow:hover,
  input[type="submit"].borrow:active,
  input[type="submit"].borrow:focus,
  input[type="reset"].borrow:hover,
  input[type="reset"].borrow:active,
  input[type="reset"].borrow:focus,
  input[type="button"].borrow:hover,
  input[type="button"].borrow:active,
  input[type="button"].borrow:focus {
    background: #8652ea;
    border-color: #8652ea;
  }
  .button.borrow.pending,
  button.borrow.pending,
  input[type="submit"].borrow.pending,
  input[type="reset"].borrow.pending,
  input[type="button"].borrow.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #9669ed 40%, #d6c4f8 50%, #9669ed 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.neutral,
  button.neutral,
  input[type="submit"].neutral,
  input[type="reset"].neutral,
  input[type="button"].neutral {
    background-color: #546e7a;
    color: #ffffff;
    border-color: #546e7a;
  }
  .button.neutral:hover,
  button.neutral:hover,
  input[type="submit"].neutral:hover,
  input[type="reset"].neutral:hover,
  input[type="button"].neutral:hover {
    text-decoration: none;
  }
  .button.neutral:hover,
  .button.neutral:active,
  .button.neutral:focus,
  button.neutral:hover,
  button.neutral:active,
  button.neutral:focus,
  input[type="submit"].neutral:hover,
  input[type="submit"].neutral:active,
  input[type="submit"].neutral:focus,
  input[type="reset"].neutral:hover,
  input[type="reset"].neutral:active,
  input[type="reset"].neutral:focus,
  input[type="button"].neutral:hover,
  input[type="button"].neutral:active,
  input[type="button"].neutral:focus {
    background: #4a606b;
    border-color: #4a606b;
  }
  .button.neutral.pending,
  button.neutral.pending,
  input[type="submit"].neutral.pending,
  input[type="reset"].neutral.pending,
  input[type="button"].neutral.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #546e7a 40%, #87a1ad 50%, #546e7a 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.hollow,
  button.hollow,
  input[type="submit"].hollow,
  input[type="reset"].hollow,
  input[type="button"].hollow {
    background: none;
    border-color: #546e7a;
    color: #90a4ae;
  }
  .button.hollow:hover,
  button.hollow:hover,
  input[type="submit"].hollow:hover,
  input[type="reset"].hollow:hover,
  input[type="button"].hollow:hover {
    border-color: #90a4ae;
    color: #cfd8dc;
  }
  .button.dark,
  button.dark,
  input[type="submit"].dark,
  input[type="reset"].dark,
  input[type="button"].dark {
    background-color: #141e27;
    color: #ffffff;
    border-color: #141e27;
  }
  .button.dark:hover,
  button.dark:hover,
  input[type="submit"].dark:hover,
  input[type="reset"].dark:hover,
  input[type="button"].dark:hover {
    text-decoration: none;
  }
  .button.dark:hover,
  .button.dark:active,
  .button.dark:focus,
  button.dark:hover,
  button.dark:active,
  button.dark:focus,
  input[type="submit"].dark:hover,
  input[type="submit"].dark:active,
  input[type="submit"].dark:focus,
  input[type="reset"].dark:hover,
  input[type="reset"].dark:active,
  input[type="reset"].dark:focus,
  input[type="button"].dark:hover,
  input[type="button"].dark:active,
  input[type="button"].dark:focus {
    background: #0b1116;
    border-color: #0b1116;
  }
  .button.dark.pending,
  button.dark.pending,
  input[type="submit"].dark.pending,
  input[type="reset"].dark.pending,
  input[type="button"].dark.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #141e27 40%, #37526a 50%, #141e27 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.outline-main,
  button.outline-main,
  input[type="submit"].outline-main,
  input[type="reset"].outline-main,
  input[type="button"].outline-main {
    border-color: #7070bb;
    color: #7070bb;
  }
  .button.outline-main:hover,
  button.outline-main:hover,
  input[type="submit"].outline-main:hover,
  input[type="reset"].outline-main:hover,
  input[type="button"].outline-main:hover {
    background: #f2fdfa;
    color: #00a071;
  }
  .button.outline-main.pending,
  button.outline-main.pending,
  input[type="submit"].outline-main.pending,
  input[type="reset"].outline-main.pending,
  input[type="button"].outline-main.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #f9fafb 40%, white 50%, #f9fafb 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.outline-borrow,
  button.outline-borrow,
  input[type="submit"].outline-borrow,
  input[type="reset"].outline-borrow,
  input[type="button"].outline-borrow {
    border-color: #9669ed;
    color: #9669ed;
  }
  .button.outline-borrow:hover,
  button.outline-borrow:hover,
  input[type="submit"].outline-borrow:hover,
  input[type="reset"].outline-borrow:hover,
  input[type="button"].outline-borrow:hover {
    background: #faf8fe;
    color: #763be8;
  }
  .button.outline-borrow.pending,
  button.outline-borrow.pending,
  input[type="submit"].outline-borrow.pending,
  input[type="reset"].outline-borrow.pending,
  input[type="button"].outline-borrow.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #f9fafb 40%, white 50%, #f9fafb 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.outline-neutral,
  button.outline-neutral,
  input[type="submit"].outline-neutral,
  input[type="reset"].outline-neutral,
  input[type="button"].outline-neutral {
    border-color: #546e7a;
    color: #546e7a;
  }
  .button.outline-neutral:hover,
  button.outline-neutral:hover,
  input[type="submit"].outline-neutral:hover,
  input[type="reset"].outline-neutral:hover,
  input[type="button"].outline-neutral:hover {
    background: #f6f8f8;
    color: #3f535c;
  }
  .button.outline-neutral.pending,
  button.outline-neutral.pending,
  input[type="submit"].outline-neutral.pending,
  input[type="reset"].outline-neutral.pending,
  input[type="button"].outline-neutral.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #f9fafb 40%, white 50%, #f9fafb 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.outline-dark,
  button.outline-dark,
  input[type="submit"].outline-dark,
  input[type="reset"].outline-dark,
  input[type="button"].outline-dark {
    border-color: #141e27;
    color: #141e27;
  }
  .button.outline-dark:hover,
  button.outline-dark:hover,
  input[type="submit"].outline-dark:hover,
  input[type="reset"].outline-dark:hover,
  input[type="button"].outline-dark:hover {
    background: #f3f4f4;
    color: #030405;
  }
  .button.outline-dark.pending,
  button.outline-dark.pending,
  input[type="submit"].outline-dark.pending,
  input[type="reset"].outline-dark.pending,
  input[type="button"].outline-dark.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #f9fafb 40%, white 50%, #f9fafb 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button.dark-blue,
  button.dark-blue,
  input[type="submit"].dark-blue,
  input[type="reset"].dark-blue,
  input[type="button"].dark-blue {
    background-color: #627eea;
    color: #ffffff;
    border-color: #627eea;
  }
  .button.dark-blue:hover,
  button.dark-blue:hover,
  input[type="submit"].dark-blue:hover,
  input[type="reset"].dark-blue:hover,
  input[type="button"].dark-blue:hover {
    text-decoration: none;
  }
  .button.dark-blue:hover,
  .button.dark-blue:active,
  .button.dark-blue:focus,
  button.dark-blue:hover,
  button.dark-blue:active,
  button.dark-blue:focus,
  input[type="submit"].dark-blue:hover,
  input[type="submit"].dark-blue:active,
  input[type="submit"].dark-blue:focus,
  input[type="reset"].dark-blue:hover,
  input[type="reset"].dark-blue:active,
  input[type="reset"].dark-blue:focus,
  input[type="button"].dark-blue:hover,
  input[type="button"].dark-blue:active,
  input[type="button"].dark-blue:focus {
    background: #4c6ce7;
    border-color: #4c6ce7;
  }
  .button.dark-blue.pending,
  button.dark-blue.pending,
  input[type="submit"].dark-blue.pending,
  input[type="reset"].dark-blue.pending,
  input[type="button"].dark-blue.pending {
    border-color: transparent;
    background: linear-gradient(120deg, #627eea 40%, #bcc8f6 50%, #627eea 60%);
    background-size: 300%;
    -webkit-animation: AnimationName 2s linear infinite;
    animation: AnimationName 2s linear infinite;
    cursor: default;
  }
  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .button:disabled,
  button:disabled,
  input[type="submit"]:disabled,
  input[type="reset"]:disabled,
  input[type="button"]:disabled {
    background: #ccd6dd !important;
    border-color: #ccd6dd !important;
    color: #ffffff !important;
  }
  .button.max,
  button.max,
  input[type="submit"].max,
  input[type="reset"].max,
  input[type="button"].max {
    border: none;
    text-align: right;
    padding: 0 1.33em 0 0;
    color: #acbbc2;
    background: none;
  }
  .button.max:hover,
  button.max:hover,
  input[type="submit"].max:hover,
  input[type="reset"].max:hover,
  input[type="button"].max:hover {
    color: #141e27;
  }

  .button.long-text {
    padding: 1.2em 0.2em;
  }

  .button + .button {
    margin-left: 0.665em;
  }

  .chevron::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: inline-block;
    height: 8px;
    left: 0px;
    position: relative;
    top: 5px;
    transform: rotate(135deg);
    vertical-align: top;
    width: 8px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  .chevron.active::before {
    transform: rotate(-45deg);
  }

  .dropdown {
    --dropdownHeight: 3em;
    --dropdownWidth: 7.75em;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .dropdown--network {
    --dropdownHeight: 36px;
    --dropdownWidth: 100px;
  }
  .dropdown__selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #070a0e;
    padding: 0.75em;
    border-radius: 3px;
    width: var(--dropdownWidth);
    height: var(--dropdownHeight);
  }
  .dropdown__selected::after {
    content: "";
    width: 6px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(./assets/icon_updown.png);
  }

  .dropdown__selected--dark2 {
    background-color: #141e27;
  }

  .dropdown p {
    flex: 1 1;
    margin-left: 0.6875em;
    line-height: 14px;
    color: #ccd6dd;
  }
  .dropdown__options {
    position: absolute;
    background-color: #070a0e;
    border-radius: 3px;
    margin-top: 0.45em;
    width: var(--dropdownWidth);
    height: var(--dropdownHeight);
    max-height: 0;
    top: var(--dropdownHeight);
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.25s ease, opacity 0.5s linear;
    z-index: 20;
  }
  .dropdown__options.active {
    visibility: visible;
    height: auto;
    min-height: var(--dropdownHeight);
    max-height: calc(var(--dropdownHeight) * 5.52);
    opacity: 1;
    transition: opacity 0.2s linear;
    overflow-y: scroll;
    transform: translateY(0);
    -webkit-animation: bounce 0.3s ease;
    animation: bounce 0.3s ease;
  }

  .dropdown__options--network {
    height: 0;
    margin-top: 0;
    top: auto;
    bottom: 2.5em;
    border-radius: 4px;
  }
  @media (max-width: 40em) {
    .dropdown__options--network {
      bottom: 3.5em;
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-3px);
    }
    30% {
      transform: translateY(2px);
    }
    85% {
      transform: translateY(-1px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(-3px);
    }
    30% {
      transform: translateY(2px);
    }
    85% {
      transform: translateY(-1px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .dot-indicator {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-indicator.red {
    background-color: #f35454;
  }
  .dot-indicator.yellow {
    background-color: #ffd24a;
  }
  .dot-indicator.green {
    background-color: #7070bb;
  }
  .dot-indicator.kovan {
    background-color: #6c58f6;
  }
  .dot-indicator.rinkeby {
    background-color: #eec55c;
  }
  .dot-indicator.goerli {
    background-color: #5197eb;
  }
  .dot-indicator.ropsten {
    background-color: #ec598d;
  }

  .icon {
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    width: 1.8em;
    height: 1.8em;
    min-width: 1.8em;
    min-height: 1.8em;
  }

  .line-icon {
    cursor: pointer;
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .line-icon--copy {
    background-image: url("./assets/icn-copy.svg");
  }
  .line-icon--copy:hover {
    background-image: url("./assets/icn-copy-green.svg");
  }
  .line-icon--external-link {
    background-image: url("./assets/icn-external-link.svg");
  }
  .line-icon--external-link:hover {
    background-image: url("./assets/icn-external-link-green.svg");
  }

  .tab-group {
    display: inline-block;
  }
  .tab-group__line {
    background-color: #ccd6dd;
    height: 2px;
    width: 100%;
    margin-top: -2px;
    z-index: 0;
  }
  .tab-group__line--dark {
    background-color: #070a0e;
  }
  .tab-group__options {
    width: 100%;
    display: inline-flex;
    z-index: 1;
  }
  .tab-group__options--align-between {
    display: flex;
    justify-content: space-between;
  }
  .tab-group__option {
    cursor: pointer;
    color: #ccd6dd;
    font-size: 1.1em;
    height: 100%;
    padding: 0.5em 0;
    margin-bottom: 0;
  }
  .tab-group__option:not(:first-child) {
    margin-left: 1.5em;
  }
  .tab-group__option--grow {
    flex-grow: 1;
    text-align: center;
  }
  .tab-group__option--landing-grey {
    color: #657786;
  }
  .tab-group__option--action-modal-grey {
    color: #aab8c1;
  }
  .tab-group__option--dark {
    color: #657786;
  }
  .tab-group__option--active {
    color: #070a0e;
    padding: 0.5em 0;
    border-bottom: 2px solid #070a0e;
  }
  .tab-group__option--active--large {
    position: relative;
    padding-bottom: 0.8em;
    border: none;
  }
  .tab-group__option--active--large::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #070a0e;
    height: 3px;
    border-radius: 10px 10px 0 0;
  }
  .tab-group__option--active--large--green {
    color: #7070bb;
  }
  .tab-group__option--active--large--green::after {
    background: #7070bb;
  }
  .tab-group__option--active--large--purple {
    color: #9669ed;
  }
  .tab-group__option--active--large--purple::after {
    background: #9669ed;
  }
  .tab-group__option--active--green {
    color: #7070bb;
    border-color: #7070bb;
  }
  .tab-group__option--active--purple {
    color: #9669ed;
    border-color: #9669ed;
  }
  .tab-group__option--active--white {
    color: #ffffff;
    border-color: #ffffff;
  }

  .docs {
    // background-color: #f9fafb;
    position: relative;
  }
  .docs section,
  .docs section#supply {
    padding-top: 80px;
    margin-top: 0.5em;
  }
  .docs h4,
  .docs h3.subsection {
    padding: 3em 0 0 0;
  }
  .docs b {
    line-height: 1.5;
  }
  .docs .endpoint {
    display: inline-flex;
    margin-top: 1.5em;
  }
  .docs .endpoint h4 {
    margin-right: 1em;
    padding-top: 0;
    margin-bottom: 0;
  }
  .docs__main-navigation {
    background-color: #ffffff;
    // height: 4.3125em;
    // border-top: 1px solid rgba(204, 214, 221, 0.5);
    // border-bottom: 1px solid rgba(204, 214, 221, 0.5);
    padding: 16px 10px 25px;
    display: flex;
    align-items: center;
    z-index: 19;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.fixed {
      position: fixed;
      top: 0;
    }
  }
  .docs__main-navigation__content {
    width: 100%;
    display: flex;
    z-index: 19;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  @media (min-width: 40em) {
    .docs__main-navigation__content {
      justify-content: flex-start;
    }
  }
  .docs__main-navigation__content .link {
    padding: 8px 0;
    display: none;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #000000;
    }
  }
  @media (min-width: 62em) {
    .docs__main-navigation__content .link {
      display: block;
    }
    .docs__main-navigation__content .link:not(:first-child) {
      margin-left: 2em;
    }
  }
  @media (min-width: 75em) {
    .docs__main-navigation__content .link:not(:first-child) {
      margin-left: 3.0625em;
    }
  }
  .docs__main-navigation__content .link.active {
    color: #000000;
    display: block;
    transition: padding 0.2s ease;
  }
  @media (min-width: 62em) {
    .docs__main-navigation__content .link.active {
      border-bottom: 2px solid #000000;
    }
  }
  .docs__main-navigation__content .link.active:hover {
    border-bottom-color: #000000;
  }
  .docs__main-navigation__content .chevron-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #ffffff;
    justify-content: center;
    padding: 0;
    // margin: 0px -2.33em 0px 0px;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  @media (min-width: 62em) {
    .docs__main-navigation__content .chevron-container {
      display: none;
    }
  }
  .docs__main-navigation__content--mobile {
    position: absolute;
    display: none;
    flex-direction: column;
    align-content: center;
    margin: 0 auto;
    top: 4.25em;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    background-color: #ffffff;
    color: #141e27;
    -webkit-font-smoothing: antialiased;
    transition: opacity 0.3s ease-in;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .docs__main-navigation__content--mobile.active {
    opacity: 1;
    display: flex;
  }
  .docs__main-navigation__content--mobile .link {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 2.33em;
    border-bottom: 1px solid rgba(204, 214, 221, 0.5);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.active {
      color: #000000;
      font-weight: 800;
    }
  }
  .docs__main-navigation.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
  }
  .docs .fixed + .docs {
    margin-top: 4.3125em;
  }
  .docs__main-section::before {
    content: "";
    background-color: #ffffff;
    position: absolute;
    right: 100%;
    margin-right: -8px;
    top: 0;
    width: 100vw;
    height: 100%;
    display: none;
    @media (max-width: 48em) {
      display: block;
    }
  }
  .docs__main-section::after {
    content: "";
    background-color: #ffffff;
    position: absolute;
    left: 100%;
    margin-left: -8px;
    top: 0;
    width: 100vw;
    height: 100%;
  }
  .docs__main-section .docs__content {
    position: relative;
    background-color: #ffffff;
    padding-bottom: 10em;
  }
  @media (min-width: 48em) {
    .docs__main-section .docs__content {
      display: block;
      padding-left: 7em;
    }
  }
  .docs__main-section--no-side-navigation::after {
    background-color: #f9fafb;
  }
  .docs__main-section--no-side-navigation .docs__content {
    background-color: #f9fafb;
    padding-left: calc(1.33em / 2);
  }
  .docs__content a {
    border-bottom: 1px solid transparent;
    color: #7070bb;
    // font-family: "Haas Grot Text R";
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0;
    padding-bottom: 0.02em;
    margin: 0px;
    text-decoration: none;
    text-transform: none;
  }
  .docs__content a:hover {
    border-bottom: 1px solid #7070bb;
  }
  .docs__content .ul-container {
    position: relative;
    padding: 0;
  }
  .docs__content .ul-container ul {
    list-style: none;
    list-style-position: outside;
    padding-left: 0;
  }
  .docs__content .ul-container ul li {
    margin-bottom: 1em;
    padding-left: 1.1em;
  }
  .docs__content .ul-container ul li a {
    border-bottom: 1px solid transparent;
    color: #7070bb;
    // font-family: "Haas Grot Text R";
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0;
    padding-bottom: 0.02em;
    margin: 0px;
    text-decoration: none;
    text-transform: none;
  }
  .docs__content .ul-container ul li a:hover {
    border-bottom: 1px solid #7070bb;
  }
  .docs__content .ul-container ul li:last-child {
    margin-bottom: 0;
  }
  .docs__content .ul-container ul li::before {
    content: "•  ";
    color: #7070bb;
    position: absolute;
    left: 0;
    margin-top: 0.26em;
  }
  .docs__content .ul-container ol {
    margin-top: 10px;
  }
  .docs__content .ul-container ol li::before {
    content: "";
  }
  .docs__content .ul-container ul li {
    margin-bottom: 0.5em;
    color: #657786;
  }
  .docs__content .ul-container ul li::before {
    margin-top: 0;
  }
  .docs__content ol li {
    margin-bottom: 1em;
    padding-left: 1.1em;
    color: #657786;
  }
  .docs__content ol li::before {
    margin-top: 0;
  }
  .docs sup {
    display: inline-block;
    font-size: 0.7em;
  }
  .docs sup p {
    display: inline-block;
  }
  .docs ul {
    line-height: 1.5;
  }
  .docs .subheader {
    margin-top: 0.55em;
  }
  .docs p {
    color: #000000;
  }
  .docs p + p {
    margin-top: 1.5em;
  }
  .docs p.optional::after {
    content: "optional";
    border-radius: 3px;
    border: 1px solid #0daef3;
    color: #0daef3;
    padding: 0.3em;
    font-size: 12px;
    // font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  }
  .docs p.notice {
    border: 1px solid #ffd24a;
    border-radius: 3px;
    padding: 0.5em 0.8866666667em;
    font-size: 0.9em;
    color: #546e7a;
  }
  .docs p + .build-item {
    margin-top: 1.5em;
  }
  .docs .build-item {
    border-bottom: none !important;
  }
  .docs img {
    padding: 40px;
    max-width: 100%;
  }
  .docs .indent-li,
  .docs .indent-li::before {
    margin-left: 40px;
  }
  .docs__side-navigation {
    padding-right: 0 !important;
    // margin-top: 15px;
    display: none;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 48em) {
    .docs__side-navigation {
      display: block;
    }
  }
  .docs__side-navigation__content {
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    padding-right: 15px;
    overflow-y: auto;
  }
  .docs__side-navigation__content .subsection,
  .docs__side-navigation__content .section {
    margin-bottom: 0.5625em;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1em;
    line-height: 1.25rem;
    font-weight: bold;
    text-transform: none;
    padding: 0.625em 0;
  }
  .docs__side-navigation__content .subsection.active,
  .docs__side-navigation__content .section.active {
    z-index: 999;
    color: #7070bb;
    border-right: 2px solid #7070bb;
    margin-right: -5px;
  }
  .docs__side-navigation__content .subsection:hover,
  .docs__side-navigation__content .section:hover {
    color: #7070bb;
  }
  .docs__side-navigation__content .subsection {
    color: #aab8c1;
  }
  .docs__side-navigation.fixed .docs__side-navigation__content {
    position: fixed;
    // top: 0;
    // bottom: auto;
    // margin-top: 7.3125em;
    // padding-bottom: 105px;
    width: 19.503em;
    z-index: 20;
    overflow-y: auto;
  }
  .docs__side-navigation.fixed--bottom .docs__side-navigation__content {
    bottom: 0;
    margin-top: 0;
    width: 19.503em;
    position: absolute;
    top: auto;
    bottom: 0;
    margin-top: 7.3125em;
    margin-bottom: 7.3125em;
  }
  .docs table {
    display: block;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    overflow-x: auto;
    line-height: 1.5em;
    padding: 0;
  }
  .docs table th {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #ccd6dd;
  }
  .docs table th,
  .docs table td {
    padding: 1em 0;
  }
  .docs table tr {
    border-bottom: 1px solid #ccd6dd;
  }
  .docs table tr > td:last-child {
    color: #657786;
    width: 100%;
  }
  .docs table.networks-table {
    padding: 2.0625em;
  }
  .docs table.networks-table th,
  .docs table.networks-table td {
    padding: 1em 0;
  }
  .docs table.networks-table th:not(:first-child),
  .docs table.networks-table td:not(:first-child) {
    padding-left: 1em;
  }
  .docs table.networks-table th:last-child,
  .docs table.networks-table td:last-child {
    padding-left: 4em;
    width: 100%;
  }
  .docs table.networks-table td:last-child {
    color: #141e27;
  }
  .docs table.networks-table thead tr {
    border-bottom: 1px solid #ccd6dd;
  }
  .docs table.networks-table tr {
    border-bottom: none;
  }
  .docs table.networks-table tr:last-child td {
    padding-bottom: 0;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  [class*="container"] {
    position: relative;
  }

  // .container-small {
  //   max-width: 40em;
  // }

  // .container {
  //   position: relative;
  //   max-width: 66em;
  // }

  // .container-large {
  //   position: relative;
  //   max-width: 82em;
  // }

  .row {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.665em;
    margin-left: -0.665em;
  }
  .row.align-middle {
    align-items: center;
  }
  .row.align-bottom {
    align-items: flex-end;
  }
  .row.align-stretch {
    align-items: stretch;
  }
  .row.align-left {
    justify-content: flex-start;
  }
  .row.align-center {
    justify-content: center;
  }
  .row.align-right {
    justify-content: flex-end;
  }
  .row.align-between {
    justify-content: space-between;
  }
  .row.reverse {
    flex-wrap: wrap-reverse;
  }

  // [class*='col'] {
  //   flex-basis: 100%;
  //   padding: 0 0.665em;
  // }

  // .col-xs-1 {
  //   flex-basis: 8.3333333333%;
  //   max-width: 8.3333333333%;
  // }

  // .col-xs-2 {
  //   flex-basis: 16.6666666667%;
  //   max-width: 16.6666666667%;
  // }

  // .col-xs-3 {
  //   flex-basis: 25%;
  //   max-width: 25%;
  // }

  // .col-xs-4 {
  //   flex-basis: 33.3333333333%;
  //   max-width: 33.3333333333%;
  // }

  // .col-xs-5 {
  //   flex-basis: 41.6666666667%;
  //   max-width: 41.6666666667%;
  // }

  // .col-xs-6 {
  //   flex-basis: 50%;
  //   max-width: 50%;
  // }

  // .col-xs-7 {
  //   flex-basis: 58.3333333333%;
  //   max-width: 58.3333333333%;
  // }

  // .col-xs-8 {
  //   flex-basis: 66.6666666667%;
  //   max-width: 66.6666666667%;
  // }

  // .col-xs-9 {
  //   flex-basis: 75%;
  //   max-width: 75%;
  // }

  // .col-xs-10 {
  //   flex-basis: 83.3333333333%;
  //   max-width: 83.3333333333%;
  // }

  // .col-xs-11 {
  //   flex-basis: 91.6666666667%;
  //   max-width: 91.6666666667%;
  // }

  // .col-xs-12 {
  //   flex-basis: 100%;
  //   max-width: 100%;
  // }

  // @media (min-width: 40em) {
  //   html,
  //   body {
  //     font-size: 14px;
  //   }

  //   .col-sm-1 {
  //     flex-basis: 8.3333333333%;
  //     max-width: 8.3333333333%;
  //   }

  //   .col-sm-2 {
  //     flex-basis: 16.6666666667%;
  //     max-width: 16.6666666667%;
  //   }

  //   .col-sm-3 {
  //     flex-basis: 25%;
  //     max-width: 25%;
  //   }

  //   .col-sm-4 {
  //     flex-basis: 33.3333333333%;
  //     max-width: 33.3333333333%;
  //   }

  //   .col-sm-5 {
  //     flex-basis: 41.6666666667%;
  //     max-width: 41.6666666667%;
  //   }

  //   .col-sm-6 {
  //     flex-basis: 50%;
  //     max-width: 50%;
  //   }

  //   .col-sm-7 {
  //     flex-basis: 58.3333333333%;
  //     max-width: 58.3333333333%;
  //   }

  //   .col-sm-8 {
  //     flex-basis: 66.6666666667%;
  //     max-width: 66.6666666667%;
  //   }

  //   .col-sm-9 {
  //     flex-basis: 75%;
  //     max-width: 75%;
  //   }

  //   .col-sm-10 {
  //     flex-basis: 83.3333333333%;
  //     max-width: 83.3333333333%;
  //   }

  //   .col-sm-11 {
  //     flex-basis: 91.6666666667%;
  //     max-width: 91.6666666667%;
  //   }

  //   .col-sm-12 {
  //     flex-basis: 100%;
  //     max-width: 100%;
  //   }

  //   .col-sm-auto {
  //     flex-basis: auto;
  //   }
  // }
  // @media (min-width: 60em) {
  //   html,
  //   body {
  //     font-size: 15px;
  //   }

  //   .col-md-1 {
  //     flex-basis: 8.3333333333%;
  //     max-width: 8.3333333333%;
  //   }

  //   .col-md-2 {
  //     flex-basis: 16.6666666667%;
  //     max-width: 16.6666666667%;
  //   }

  //   .col-md-3 {
  //     flex-basis: 25%;
  //     max-width: 25%;
  //   }

  //   .col-md-4 {
  //     flex-basis: 33.3333333333%;
  //     max-width: 33.3333333333%;
  //   }

  //   .col-md-5 {
  //     flex-basis: 41.6666666667%;
  //     max-width: 41.6666666667%;
  //   }

  //   .col-md-6 {
  //     flex-basis: 50%;
  //     max-width: 50%;
  //   }

  //   .col-md-7 {
  //     flex-basis: 58.3333333333%;
  //     max-width: 58.3333333333%;
  //   }

  //   .col-md-8 {
  //     flex-basis: 66.6666666667%;
  //     max-width: 66.6666666667%;
  //   }

  //   .col-md-9 {
  //     flex-basis: 75%;
  //     max-width: 75%;
  //   }

  //   .col-md-10 {
  //     flex-basis: 83.3333333333%;
  //     max-width: 83.3333333333%;
  //   }

  //   .col-md-11 {
  //     flex-basis: 91.6666666667%;
  //     max-width: 91.6666666667%;
  //   }

  //   .col-md-12 {
  //     flex-basis: 100%;
  //     max-width: 100%;
  //   }

  //   .col-md-auto {
  //     flex-basis: auto;
  //   }
  // }
  // @media (min-width: 82em) {
  //   html,
  //   body {
  //     font-size: 16px;
  //   }

  //   .col-lg-1 {
  //     flex-basis: 8.3333333333%;
  //     max-width: 8.3333333333%;
  //   }

  //   .col-lg-2 {
  //     flex-basis: 16.6666666667%;
  //     max-width: 16.6666666667%;
  //   }

  //   .col-lg-3 {
  //     flex-basis: 25%;
  //     max-width: 25%;
  //   }

  //   .col-lg-4 {
  //     flex-basis: 33.3333333333%;
  //     max-width: 33.3333333333%;
  //   }

  //   .col-lg-5 {
  //     flex-basis: 41.6666666667%;
  //     max-width: 41.6666666667%;
  //   }

  //   .col-lg-6 {
  //     flex-basis: 50%;
  //     max-width: 50%;
  //   }

  //   .col-lg-7 {
  //     flex-basis: 58.3333333333%;
  //     max-width: 58.3333333333%;
  //   }

  //   .col-lg-8 {
  //     flex-basis: 66.6666666667%;
  //     max-width: 66.6666666667%;
  //   }

  //   .col-lg-9 {
  //     flex-basis: 75%;
  //     max-width: 75%;
  //   }

  //   .col-lg-10 {
  //     flex-basis: 83.3333333333%;
  //     max-width: 83.3333333333%;
  //   }

  //   .col-lg-11 {
  //     flex-basis: 91.6666666667%;
  //     max-width: 91.6666666667%;
  //   }

  //   .col-lg-12 {
  //     flex-basis: 100%;
  //     max-width: 100%;
  //   }

  //   .col-lg-auto {
  //     flex-basis: auto;
  //   }
  // }
  div > .elmsh {
    margin-top: 1.5em;
  }
  .elmsh {
    color: #9aaab1;
    background: #f9fafb;
    font-size: 0.9em;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .elmsh-hl {
    background: #fffbdd;
  }

  .elmsh-add {
    background: #eaffea;
  }

  .elmsh-del {
    background: #ffecec;
  }

  .elmsh-comm {
    color: #969896;
  }

  .elmsh1 {
    color: #0daef3;
  }

  .elmsh2 {
    color: #0daef3;
  }

  .elmsh3 {
    color: #9669ed;
  }

  .elmsh4 {
    color: #9669ed;
  }

  .elmsh5 {
    color: #7070bb;
  }

  .elmsh6 {
    color: #005cc5;
  }

  .elmsh7 {
    color: #070a0e;
  }

  .inline-code {
    // font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: small;
    color: #141e27;
    background-color: #f9fafb;
    padding: 0.2em;
  }

  .elmsh-line:before {
    content: attr(data-elmsh-lc);
    display: inline-block;
    text-align: right;
    width: 40px;
    padding: 0 20px 0 0;
    opacity: 0.3;
  }

  .brand {
    display: inline-block;
    background-image: url(../../assets/images/docs/compound-logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    width: 121px;
    height: 27px;
  }

  .mark {
    display: inline-block;
    background-image: url(./assets/compound-mark.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: transparent;
    width: 29px;
    height: 37px;
  }

  .field {
    display: flex;
    justify-content: space-between;
    padding: 1.33em;
  }
  .field label {
    margin: 0;
  }
  .field .balance {
    text-align: center;
  }
  .field .subtitle {
    margin-top: 0.5em;
    font-size: 0.9em;
    color: #acbbc2;
  }

  input {
    border: none;
    border-radius: 5px;
    outline: 0;
    width: 100%;
    padding: 1em;
    // font-family: inherit;
    font-size: 1em;
    font-weight: 600;
    color: #141e27;
    transition: all 0.2s linear;
  }

  input::-moz-placeholder {
    font-weight: 400;
    color: #acbbc2;
    padding-right: 1.6em;
  }

  input:-ms-input-placeholder {
    font-weight: 400;
    color: #acbbc2;
    padding-right: 1.6em;
  }

  input::-ms-input-placeholder {
    font-weight: 400;
    color: #acbbc2;
    padding-right: 1.6em;
  }

  input::placeholder {
    font-weight: 400;
    color: #acbbc2;
    padding-right: 1.6em;
  }

  button:disabled {
    cursor: default;
  }

  input:disabled + button {
    display: none;
  }

  .build-item {
    border-top: 1px solid #d1d6db;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2em 0 !important;
    transition: all 0.3s ease-in-out;
  }
  .build-item label {
    width: 4em;
  }
  .build-item .build-item-text {
    flex-grow: 1;
    margin: 0;
    color: #141e27;
  }
  .build-item .build-item-text > * {
    pointer-events: auto;
  }
  .build-item:hover .build-item-text {
    color: #7070bb;
  }

  header {
    padding: 1em 0;
    background: #070a0e;
    min-height: 80px;
  }
  header.dark-2 {
    background-color: #141e27;
  }
  header .brand {
    background-image: url(../../assets/images/docs/compound-logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    width: 127px;
    height: 27px;
  }
  header .text-center a {
    margin: 0 0.665em;
    color: #ffffff;
    letter-spacing: 1px;
  }
  header .text-center a.active {
    color: #7070bb;
    padding: 0.3em 0;
    border-bottom: 1px solid #7070bb;
    transition: padding 0.2s ease;
  }
  header .text-center a:hover {
    color: #7070bb;
    text-decoration: none;
    padding-bottom: 0.5em;
  }
  header .text-right .button {
    padding: 1em 1.6em;
  }
  header .hamburger {
    display: none;
    position: relative;
    z-index: 25;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  header .hamburger span {
    display: block;
    margin-left: auto;
    width: 20px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }
  header .hamburger.active span {
    opacity: 1;
    transform: rotate(45deg) translate(0, 2px);
  }
  header .hamburger.active span:nth-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  header .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(1px, -5px);
  }
  header .comp-balance {
    background: #141e27;
    border-radius: 3px;
    cursor: pointer;
    padding: 0.43em 0.665em;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
  }
  header .comp-balance .icon {
    margin-left: 0.25em;
    height: 1.25em;
    width: 1.25em;
    min-height: 1.25em;
    min-width: 1.25em;
  }
  header .comp-balance:hover {
    background: #1d2b38;
  }
  header #account {
    background: #141e27;
    border-radius: 3px;
    padding: 0.665em;
    margin-left: 1.33em;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
  }
  header #account:hover {
    background: #1d2b38;
  }
  header #account .icon {
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 12px;
    height: 12px;
    min-width: 0;
    min-height: 0;
    margin-right: 0.665em;
  }
  header #account .icon.coinbase {
    background-image: url(../../assets/images/docs/icn-coinbase-wallet-connected.svg);
  }
  header #account .icon.ledger.light {
    background-image: url(../../assets/images/docs/icn-ledger-connected-light.svg);
  }
  header #account .icon.ledger.dark {
    background-image: url(../../assets/images/docs/icn-ledger-connected-dark.svg);
  }
  header #account .icon.metamask {
    background-image: url(../../assets/images/docs/icn-metamask-connected.svg);
  }
  header #select-currency {
    padding-right: 0.25em;
  }
  header.light {
    background: #ffffff;
  }
  header.light .brand {
    background-image: url(../../assets/images/docs/compound-logo-dark.svg);
  }
  header.light .text-center a {
    color: #141e27;
  }
  header.light .text-center a.active {
    color: #7070bb;
    padding: 0.3em 0;
    border-bottom: 1px solid #7070bb;
    transition: padding 0.2s ease;
  }
  header.light .text-center a:hover {
    color: #7070bb;
    text-decoration: none;
    padding-bottom: 0.5em;
  }
  header.light .hamburger span {
    background-color: #070a0e;
  }
  header.light #account {
    background: #f9fafb;
    color: #141e27;
  }
  header.light #account:hover {
    color: #7070bb;
  }
  header.light #account.active {
    border-bottom: 2px solid #7070bb;
  }
  header .mobile-header {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0 auto;
    padding-top: 7.98em;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 20;
    overflow: scroll;
    opacity: 0;
    background-color: #070a0e;
    -webkit-font-smoothing: antialiased;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.3s ease-in;
  }
  header .mobile-header.active {
    transform: none;
    opacity: 1;
  }
  header .mobile-header.light {
    background-color: #ffffff;
  }
  header .mobile-header.light li {
    color: #141e27;
    background-color: #ffffff;
  }
  header .mobile-header.dark-2 {
    background-color: #141e27;
  }
  header .mobile-header .links {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header .mobile-header ul {
    list-style-type: none;
    width: 100%;
    padding-left: 0px;
    background-color: transparent;
    margin-bottom: 0;
  }
  header .mobile-header li {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 22px;
    color: #ffffff;
  }
  header .mobile-header li p {
    margin: 0 auto;
  }
  header .mobile-header .dapp {
    margin-bottom: 55px;
    margin-left: 42px;
    margin-right: 42px;
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }
  @media (max-width: 40em) {
    header.landing .hamburger {
      display: block;
    }
  }
  header.landing .logo {
    z-index: 25;
  }
  header.landing .links a {
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
  }
  header.landing .dapp {
    color: #7070bb;
    border-color: #7070bb;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
  }
  header.landing .dapp:hover {
    background-color: #7070bb;
    color: #ffffff;
    border-color: #7070bb;
  }

  header.dapp #connect-wallet {
    border-radius: 3px;
    margin-left: 1.33em;
    padding: 0.665em;
    color: #7070bb;
    border-color: #7070bb;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    text-transform: none;
  }
  header.dapp #connect-wallet:hover {
    background-color: #7070bb;
    color: #ffffff;
    border-color: #7070bb;
  }
  header.dapp .links a {
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
  }
  header.dapp .links a.active {
    color: #7070bb;
    padding: 0.3em 0;
    border-bottom: 1px solid #7070bb;
    transition: padding 0.2s ease;
  }
  header.dapp .links a:hover {
    color: #7070bb;
    text-decoration: none;
    padding-bottom: 0.5em;
  }
  header.dapp .actions {
    display: none;
    align-items: center;
    justify-content: flex-end;
    min-height: 3em;
  }
  header.dapp .actions > * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #cfd8dc;
  }
  @media (min-width: 40em) {
    header.dapp .actions {
      display: flex;
    }
  }
  header.dapp .mobile-links {
    display: flex;
    justify-content: flex-end;
  }
  header.dapp .mobile-links__link {
    border-radius: 3px;
    padding: 0.9975em !important;
    color: #7070bb;
    border-color: #7070bb;
    text-transform: none;
  }
  @media (min-width: 40em) {
    header.dapp .mobile-links {
      display: none;
    }
  }

  #line {
    height: 1px;
    width: 100%;
    background: #657786;
    opacity: 0.3;
  }

  .arrow {
    width: 17px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/images/docs/icn-arrow.svg);
    margin-right: 5px;
  }
  .arrow.big {
    width: 34px;
    height: 32px;
    background-position: center;
    background-size: 100%;
    background-image: url(../../assets/images/docs/icn-build-arrows.svg);
    transition: all 0.2s ease-in-out;
  }
  .arrow.big.green {
    background-image: url(../../assets/images/docs/icn-build-arrows-green.svg);
  }
  :hover > .arrow.big,
  .arrow.big:hover {
    background-image: url(../../assets/images/docs/icn-build-arrows-green.svg);
    transform: translateX(5px);
  }

  #rToken-info {
    padding-top: 3.25em;
    padding-bottom: 3.25em;
  }
  #rToken-info .faq-item {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    text-transform: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  #rToken-info .faq-item .faq-item-header {
    padding: 2.8125em 0 2.8125em;
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  #rToken-info .faq-item .faq-item-text {
    flex-grow: 1;
    margin-bottom: 0;
    color: #070a0e;
  }
  #rToken-info .faq-item .faq-item-description {
    margin-bottom: 2.8125em;
    padding-right: 32px;
    color: #aab8c1;
    display: none;
    position: relative;
  }
  #rToken-info .faq-item .faq-item-description.active {
    display: block;
  }
  #rToken-info .faq-item .faq-item-description p:not(:first-child) {
    margin-top: 0.7em;
  }
  #rToken-info .faq-item .faq-item-description a {
    border-bottom: 1px solid transparent;
    color: #7070bb;
    // font-family: "Haas Grot Text R";
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0;
    padding-bottom: 0.02em;
    margin: 0px;
    text-decoration: none;
    text-transform: none;
  }
  #rToken-info .faq-item .faq-item-description a:hover {
    border-bottom: 1px solid #7070bb;
  }
  #rToken-info .faq-item .faq-item-description ul {
    list-style: none;
    list-style-position: outside;
    padding-left: 0;
  }
  #rToken-info .faq-item .faq-item-description ul li {
    margin-bottom: 1em;
    padding-left: 1.1em;
  }
  #rToken-info .faq-item .faq-item-description ul li a {
    border-bottom: 1px solid transparent;
    color: #7070bb;
    // font-family: "Haas Grot Text R";
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0;
    padding-bottom: 0.02em;
    margin: 0px;
    text-decoration: none;
    text-transform: none;
  }
  #rToken-info .faq-item .faq-item-description ul li a:hover {
    border-bottom: 1px solid #7070bb;
  }
  #rToken-info .faq-item .faq-item-description ul li:last-child {
    margin-bottom: 0;
  }
  #rToken-info .faq-item .faq-item-description ul li::before {
    content: "•  ";
    color: #7070bb;
    position: absolute;
    left: 0;
    margin-top: 0.26em;
  }
  #rToken-info .faq-item .faq-item-description ol {
    margin-top: 10px;
  }
  #rToken-info .faq-item .faq-item-description ol li::before {
    content: "";
  }
  #rToken-info .faq-item:hover {
    border-bottom: 1px solid #7070bb;
  }
  #rToken-info .plus {
    height: 2em;
    width: 2em;
    position: relative;
  }
  #rToken-info .plus span {
    position: absolute;
    transition: 0.3s;
    background: #7070bb;
  }
  #rToken-info .plus span:first-of-type {
    top: 0%;
    bottom: 0%;
    width: 10%;
    left: 45%;
  }
  #rToken-info .plus span:first-of-type.active {
    transform: rotate(90deg);
  }
  #rToken-info .plus span:last-of-type {
    left: 0%;
    right: 0%;
    height: 10%;
    top: 45%;
  }
  #rToken-info .plus span:last-of-type.active {
    left: 50%;
    right: 50%;
  }
  #rToken-info .plus.active span:first-of-type {
    transform: rotate(90deg);
  }
  #rToken-info .plus.active span:last-of-type {
    left: 50%;
    right: 50%;
  }

  .contract {
    display: flex;
    align-items: center;
    min-width: 255px;
  }
  .contract .token-symbol {
    margin-left: 0.5em;
    min-width: 3.4375em;
  }

  .token-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.75em;
    padding: 0 0.9em;
    font-size: 10px;
    width: 61px;
    height: 22px;
    border-radius: 100px;
    background-color: #0daef3;
    color: #ffffff;
    margin-bottom: 1px;
  }

  span.payable {
    content: "payable";
    margin-left: 0.5em;
    border-radius: 3px;
    border: 1px solid #0daef3;
    color: #0daef3;
    padding: 0.3em;
    font-size: 12px;
    // font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  }

  .networks-tab-group {
    margin-top: 1.5em;
  }

  .networks-carousel {
    margin-top: 1.125em;
    margin-bottom: 1.125em;
    border: 1px solid #ccd6dd;
  }

  .contract-address {
    display: flex;
    align-items: center;
  }
  .contract-address__value {
    min-width: 220px;
  }
  .contract-address .line-icon {
    margin-left: 1em;
  }
  .contract-address a.external-link {
    border-bottom: none;
    padding-bottom: 0;
    line-height: 1em;
    height: 17px;
  }
  .contract-address a.external-link:hover {
    border-bottom: none;
  }

  table.networks-table {
    padding: 2.0625em;
  }
  table.networks-table th,
  table.networks-table td {
    padding: 1em 0;
  }
  table.networks-table th:not(:first-child),
  table.networks-table td:not(:first-child) {
    padding-left: 1em;
  }
  table.networks-table th:last-child,
  table.networks-table td:last-child {
    padding-left: 4em;
    width: 100%;
  }
  table.networks-table td:last-child {
    color: #141e27;
  }
  table.networks-table thead tr {
    border-bottom: 1px solid #ccd6dd;
  }
  table.networks-table tr {
    border-bottom: none;
  }
  table.networks-table tr:last-child td {
    padding-bottom: 0;
  }
  table.token-decimal-table {
    padding-top: 1.375em;
    line-height: 0.875em;
    color: #141e27;
    max-width: 80%;
    margin: auto;
    text-align: center;
  }
  table.token-decimal-table th,
  table.token-decimal-table td {
    width: 25%;
  }
  @media (min-width: 40em) {
    table.token-decimal-table th,
    table.token-decimal-table td {
      font-size: 12px;
    }
  }
  @media (min-width: 60em) {
    table.token-decimal-table th,
    table.token-decimal-table td {
      font-size: 14px;
    }
  }
  @media (min-width: 82em) {
    table.token-decimal-table th,
    table.token-decimal-table td {
      font-size: 15px;
    }
  }
  table.token-decimal-table td:last-child {
    color: #141e27;
  }
  table.costs-table {
    margin-top: 3.375em;
    line-height: 0.875em;
    padding: 0;
  }
  table.costs-table th,
  table.costs-table td {
    padding: 1.15em 0;
  }
  table.costs-table th:first-child,
  table.costs-table td:first-child {
    min-width: 15em;
  }
  table.costs-table th:last-child,
  table.costs-table td:last-child {
    width: 100%;
  }
  table.costs-table td:last-child {
    color: #141e27;
  }

  table.key-events-table tr > td:first-child {
    max-width: 420px;
    padding-right: 6em;
  }
  table.key-events-table tr > td:last-child {
    color: #657786;
    padding-right: 10em;
  }
  table.errors td:first-child {
    min-width: 4em;
  }
  table.errors td:nth-child(2) {
    min-width: 20em;
  }
  table.failure-infos td:first-child {
    min-width: 4em;
  }
  table.failure-infos td:nth-child(2) {
    width: 100%;
  }
  table.api-table td:first-child {
    min-width: 4em;
  }
  table.api-table td:nth-child(2) {
    padding-left: 1.5em;
    min-width: 19em;
  }
  table.api-table td:last-child {
    padding-right: 2em;
  }
  table.api-table th:nth-child(2) {
    padding-left: 1.5em;
  }
  table.api-table pre {
    background-color: #f9fafb;
  }
  table.api-table pre p {
    // font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: small;
    color: #141e27;
  }

  body {
    // font-family: "Haas Grot Text R", sans-serif;
    font-weight: 500;
    line-height: 1;
    color: #141e27;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 1em 0;
    text-rendering: optimizelegibility;
    font-weight: 500;
  }

  h1 {
    // font-family: "Haas Grot Disp R";
    font-style: normal;
    font-size: 2.462em;
    line-height: 3.231em;
  }
  @media (min-width: 60em) {
    h1 {
      font-size: 3.5em;
      line-height: 4.5em;
    }
  }

  h2 {
    // font-family: "Haas Grot Disp R";
    font-style: normal;
    font-weight: bold;
    font-size: 1.875em;
    line-height: 160%;
  }

  h3 {
    // font-family: "Haas Grot Disp R";
    font-weight: 700;
    font-style: normal;
    font-size: 1.5em;
    line-height: 2.1em;
    letter-spacing: 0;
  }

  h4 {
    // font-family: "Haas Grot Disp R";
    font-weight: 700;
    font-style: normal;
    font-size: 1.35em;
    line-height: 1.75em;
    letter-spacing: 0;
  }
  @media (min-width: 60em) {
    h4 {
      font-size: 1.1em;
    }
  }

  h5 {
    // font-family: "Haas Grot Disp R";
    font-style: normal;
    font-size: 1.35em;
    line-height: 1.75em;
    letter-spacing: 0;
  }
  @media (min-width: 60em) {
    h5 {
      font-size: 1.1em;
    }
  }

  p {
    // font-family: "Haas Grot Text R";
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    margin: 0px;
  }
  p.small {
    font-size: 12px;
  }
  p.p--2 {
    font-size: 0.94em;
    font-weight: 500;
    line-height: 1;
  }

  pre {
    padding: 1.75em 1.33em;
    font-size: 1em;
    line-height: 1.25;
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 3px;
    counter-reset: line;
    // font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    text-align: left;
    white-space: nowrap;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  .line-numbers {
    background-color: #7070bb;
    height: 100%;
    opacity: 0.1;
    width: 3.4375em;
  }

  code {
    background-color: #ffffff;
  }
  code .token {
    color: #aab8c1;
  }
  code .token.keyword {
    color: #9669ed;
  }
  code .token.function {
    color: #7070bb;
  }
  code .token.argument {
    color: #141e27;
  }
  code .token.string {
    color: #0daef3;
  }
  code .code-line {
    counter-incement: line;
    padding-left: 1.43em;
  }
  code .code-line:before {
    margin-right: 2em;
    color: #7070bb;
    content: counter(line);
    -webkit-user-select: none;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-justify {
    text-align: justify;
  }

  .label,
  label {
    cursor: inherit;
    // font-family: "Haas Grot Text R";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
  .label.big,
  label.big {
    // font-family: "Haas Grot Disp R";
    font-weight: bold;
    font-style: normal;
    font-size: 1.25em;
  }
  .label.medium,
  label.medium {
    // font-family: "Haas Grot Disp R";
    font-weight: 500;
    font-style: normal;
    font-size: 1.5em;
  }
  .label.dark,
  label.dark {
    color: #657786;
  }
  .label.supply,
  label.supply {
    color: #7070bb;
  }
  .label.borrow,
  label.borrow {
    color: #9669ed;
  }

  a {
    color: inherit;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }

  a:focus {
    outline: 0;
  }

  a:active,
  a:hover {
    color: #7070bb;
    outline: 0;
    text-decoration: none;
  }

  .text-small {
    font-size: 0.8em;
  }

  .text-large {
    font-size: 1.2em;
  }

  .text-largest {
    font-size: 1.4em;
  }

  .null {
    color: #cfd8dc;
  }

  .headline {
    font-size: 1.39em;
    font-weight: 300;
    color: #ffffff;
    margin-top: 0.7em;
  }

  .italic {
    font-style: italic;
  }

  .rtoken {
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    background-image: url("../../assets/images/docs/rtoken_blank.svg");
    width: 3.375em;
    height: 3.375em;
  }
  .rtoken--small {
    width: 26px;
    height: 26px;
  }
  .rtoken--cAMPL {
    background-image: url("../../assets/images/docs/rtoken_ampl.svg");
  }
  .rtoken--rBAT {
    background-image: url("../../assets/images/docs/rtoken_bat_product.svg");
  }
  .rtoken--rCOMP {
    background-image: url("../../assets/images/docs/rtoken_comp_product.svg");
  }
  .rtoken--rDAI {
    background-image: url("../../assets/images/docs/rtoken_dai_product.svg");
  }
  .rtoken--rETH {
    background-image: url("../../assets/images/docs/rtoken_eth_product.svg");
  }
  .rtoken--cFXC {
    background-image: url("../../assets/images/docs/rtoken_fxc.svg");
  }
  .rtoken--cHT {
    background-image: url("../../assets/images/docs/rtoken_ht.svg");
  }
  .rtoken--rLINK {
    background-image: url("../../assets/images/docs/rtoken_link_product.svg");
  }
  .rtoken--cLOOM {
    background-image: url("../../assets/images/docs/rtoken_loom.svg");
  }
  .rtoken--cMANA {
    background-image: url("../../assets/images/docs/rtoken_mana.svg");
  }
  .rtoken--cMKR {
    background-image: url("../../assets/images/docs/rtoken_mkr.svg");
  }
  .rtoken--cNMR {
    background-image: url("../../assets/images/docs/rtoken_nmr.svg");
  }
  .rtoken--cOMG {
    background-image: url("../../assets/images/docs/rtoken_omg.svg");
  }
  .rtoken--cPAX {
    background-image: url("../../assets/images/docs/rtoken_pax.svg");
  }
  .rtoken--rREP {
    background-image: url("../../assets/images/docs/rtoken_rep_product.svg");
  }
  .rtoken--rSAI {
    background-image: url("../../assets/images/docs/rtoken_sai_product.svg");
  }
  .rtoken--cSUSD {
    background-image: url("../../assets/images/docs/rtoken_susd.svg");
  }
  .rtoken--cTBTC {
    background-image: url("../../assets/images/docs/rtoken_tbtc_product.svg");
  }
  .rtoken--cTBTC--product {
    background-image: url("../../assets/images/docs/rtoken_tbtc_product.svg");
  }
  .rtoken--rTUSD {
    background-image: url("../../assets/images/docs/rtoken_tusd_product.svg");
  }
  .rtoken--rUNI {
    background-image: url("../../assets/images/docs/rtoken_uni_product.svg");
  }
  .rtoken--rUSDC {
    background-image: url("../../assets/images/docs/ic_token_usd-coin.svg");
  }
  .rtoken--rUSDT {
    background-image: url("../../assets/images/docs/ic_token_tether.svg");
  }
  .rtoken--rWBTC {
    background-image: url("../../assets/images/docs/rtoken_wbtc_product.svg");
  }
  .rtoken--rWBTC2 {
    background-image: url("../../assets/images/docs/rtoken_wbtc_product.svg");
  }
  .rtoken--rBUSD {
    background-image: url("../../assets/images/docs/BUSD.png");
  }

  body {
    background: #f9fafb;
    -webkit-tap-highlight-color: transparent;
    min-height: 100vh;
  }

  #main {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }
  #main > div:not(.alert) {
    flex-grow: 1;
  }
  #main
    > div:not(.alert)
    > :last-child:not(.docs__main-section, .legacy-panel) {
    padding-bottom: 10em;
  }

  .mobile-only {
    display: none;
  }

  @media (max-width: 40em) {
    .mobile-hide {
      display: none;
    }

    .mobile-only {
      display: block;
    }
    .mobile-only.logo {
      display: block;
    }

    // .container-large {
    //   padding-right: 0.8866666667em;
    //   padding-left: 0.8866666667em;
    // }

    // .container {
    //   padding-right: 0.8866666667em;
    //   padding-left: 0.8866666667em;
    // }

    // .container-small {
    //   padding-right: 0.8866666667em;
    //   padding-left: 0.8866666667em;
    // }

    .button {
      padding-top: 1.2em !important;
      padding-bottom: 1.2em !important;
    }
  }
}
