.box-wrap {
  margin-bottom: 20px;
  cursor: pointer;
  .box-game-card {
    background-repeat: no-repeat;
    background-size: contain;
    width: 254px;
    height: 400px;
    min-width: 254px;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.bronze {
      background-image: url("./../images/wallet/bronze_card.png");
    }
    &.silver {
      background-image: url("./../images/wallet/silver_card.png");
    }
    &.gold {
      background-image: url("./../images/wallet/gold_card.png");
    }
    &:hover {
      filter: drop-shadow(0px 4px 68px rgba(252, 177, 0, 0.39));
    }
    img {
      mix-blend-mode: lighten;
      width: 90%;
    }
    .name-box {
      font-size: 32px;
      font-family: "Bebas Neue";
      letter-spacing: 0.02em;
      text-shadow: 0px 0px 23px #ec8438;
    }
  }

  .box_id {
    text-align: center;
  }
  .amount_box {
    text-align: center;
    .number {
      font-weight: bold;
      color: #fcb100;
    }
  }
  .box-delist,
  .box-btn-buy {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions {
    .app-btn {
      min-width: 45%;
      margin: 5px;
    }
  }
}
