@font-face {
  font-family: "UTM Bebas";
  src: local("UTM Bebas"), url(../fonts/UTM_Bebas.ttf) format("truetype");
}

html {
  height: unset;
}
body {
  padding: 0;
  background-color: #1b2931 !important;
  color: #ffffff !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;

  background: url("../../assets/images/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

@import "./variable.scss";
@import "./common.scss";
@import "./layout.scss";
@import "./marketplace.scss";
@import "./wallet.scss";
@import "../../components/Docs/docs.scss";
@import "./pagination.scss";
@import "./loading.scss";
@import "./detail_footballer.scss";
@import "./dashboard.scss";
@import "./top_players.scss";
@import "./filters.scss";
@import "./modal_sell.scss";
@import "./modal_delist.scss";
@import "./token_vault.scss";
@import "./the_countdown.scss";
@import "./vault.scss";
@import "./nftvault.scss";
@import "./box_card.scss";
@import "./nft_box_detail.scss";
@import "./game_box_list.scss";
