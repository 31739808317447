$colorNeutral400: #c2c6c9;
$colorWhite: #ffffff;
$colorBastille: #303035;
$colorDarkGray: #aeaeae;
$colorPayneGrey: #45454a;
$colorCornflowerBlue: #6398ff;
$colorJaguar: #222228;
$colorBlackRussian: #191920;
$colorPrimary: #fcb100;
$colorModalBg: #1b1b1b;
