.today-vault-tooltip {
  font-size: 12px;
  .ant-tooltip-inner {
    background-color: #45464c;
  }
  .ant-tooltip-arrow .ant-tooltip-arrow-content {
    background-color: #45464c;
  }
}

.token-vault {
  p {
    margin: unset;
  }

  ul {
    margin: unset;
    padding: unset;
    list-style: none;
  }

  .chart {
    // flex: 1 1;
    // background-color: #ffffff;
    svg {
      overflow: visible;
    }
  }

  .btn {
    color: $colorPrimary;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 1px solid $colorPrimary;
    border-radius: 6px;
  }

  padding: 150px 0;
  background: url("/assets/images/detail_footballer/banner.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 99;
  @media screen and (min-width: 2000px) {
    background-size: 100% 100%;
  }

  &__info {
    display: flex;

    .chart-tvl {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tvl {
        padding: 1.5rem 1.5rem;
        background: linear-gradient(
            0deg,
            rgba(3, 9, 60, 0.3) 12.36%,
            rgba(19, 23, 55, 0) 100%
          ),
          rgba(0, 0, 0, 0.1);
        border: 1px solid #383743;
        border-top: 2px solid #0083fc;
        box-sizing: border-box;
        backdrop-filter: blur(12px);
        border-radius: 2px;

        ul {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 1rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0 2rem;

          li {
            width: 100%;
            margin: 0.2rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              color: $colorPrimary;
              font-size: 1.2rem;
              text-align: center;
              font-weight: bold;
            }

            span {
              display: flex;
              margin: auto;
              align-items: center;
              text-align: center;
              font-weight: bold;
              font-size: 1.2rem;
              line-height: 25px;
              background: -webkit-linear-gradient(
                180deg,
                #ffffff 20.83%,
                #c6c4c9 41.86%,
                #ffffff 77.44%,
                #aeabb2 100%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              .coin-icon {
                margin-left: 0.5rem;
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }

      .chart {
        height: 800px;
        flex: 1 1;
        margin-top: 1rem;
        background: linear-gradient(
            0deg,
            rgba(3, 9, 60, 0.3) 12.36%,
            rgba(19, 23, 55, 0) 100%
          ),
          rgba(0, 0, 0, 0.1);
        border: 1px solid #383743;
        // border-top: 2px solid #0083fc;
        box-sizing: border-box;
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12 px);
        border-radius: 2px;
      }
    }

    .count-down {
      flex: 1 1;
      margin-right: 1rem;
      text-align: center;
      background: linear-gradient(
          0deg,
          rgba(3, 9, 60, 0.3) 12.36%,
          rgba(19, 23, 55, 0) 100%
        ),
        rgba(0, 0, 0, 0.1);

      .total-usdt {
        font-size: 3.5rem;
        margin-top: 20px;
        color: rgb(246, 85, 85);
        font-weight: bold;
      }

      .info1 {
        font-size: 1.3rem;
        margin-top: 10px;
      }
      .info2 {
        font-size: 1rem;
        margin-top: 10px;
      }
      .daily-price {
        border: 1.5px solid #fff;
        border-radius: 5px;
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        padding: 8px;
        cursor: pointer;
      }
    }

    .info {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      &__locktime {
        padding: 1rem 1rem;
        display: flex;
        flex-wrap: wrap;
        background: linear-gradient(
            0deg,
            rgba(3, 9, 60, 0.3) 12.36%,
            rgba(19, 23, 55, 0) 100%
          ),
          rgba(0, 0, 0, 0.1);
        border: 1px solid #383743;
        border-top: 2px solid #0083fc;
        box-sizing: border-box;
        backdrop-filter: blur(12px);
        border-radius: 2px;

        .countdown {
          margin: 0.5rem 0.5rem;

          .title {
            font-size: 1.2rem;
            font-family: "Poppins";
            margin-bottom: 0.5rem;
            text-align: center;
            p {
              font-size: 0.8rem;
            }
          }

          ul {
            list-style: none;
            display: flex;
            padding: unset;
            margin: unset;
            li {
              margin: 0.2rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              span:first-child {
                font-weight: bold;
                font-size: 2rem;
                background: -webkit-linear-gradient(
                  180deg,
                  #ffffff 20.83%,
                  #c6c4c9 41.86%,
                  #ffffff 77.44%,
                  #aeabb2 100%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              span:last-child {
                color: #aeaeae;
                font-size: 0.7rem;
              }
              .dot {
                line-height: 50px;
              }
            }
          }

          flex: 1 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.5rem;
            text-align: center;
            white-space: nowrap;
          }
        }

        .lock {
          flex: 1 1;
          margin: auto;

          &__title {
            font-size: 1rem;
            font-family: "Poppins";
            margin-bottom: 0.5rem;
            text-align: center;
          }

          &__item {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding: 0.5rem 0;
            font-family: "Poppins", sans-serif;
            font-size: 1.6rem;
            font-weight: bold;
            white-space: nowrap;
            letter-spacing: 2px;

            div:first-child {
              color: rgba(255, 255, 255, 0.7);
              margin-right: 0.2rem;
            }

            div:last-child {
              color: #fff;
              margin-left: 0.2rem;
            }
          }
        }
      }

      &__description {
        margin-top: 1rem;

        .title {
          font-family: "Bebas Neue", cursive;
          font-size: 3rem;
          text-align: center;
        }

        .content {
          height: 100%;
          padding: 1rem;
          background: linear-gradient(
              0deg,
              rgba(3, 9, 60, 0.3) 12.36%,
              rgba(19, 23, 55, 0) 100%
            ),
            rgba(0, 0, 0, 0.1);
          border: 1px solid #383743;
          // border-top: 2px solid #0083fc;
          box-sizing: border-box;
          backdrop-filter: blur(12px);
          border-radius: 2px;

          ul {
            list-style: disc;
            margin-left: 1rem;
          }

          li {
            margin: 0.8rem 0;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
          }
        }
      }
    }
  }

  &__main {
    margin-top: 2rem;

    .boxes {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .box {
        background: linear-gradient(
            0deg,
            rgba(3, 9, 60, 0.3) 12.36%,
            rgba(19, 23, 55, 0) 100%
          ),
          rgba(0, 0, 0, 0.1);
        border: 1px solid #383743;
        border-top: 2px solid #0083fc;
        box-sizing: border-box;
        backdrop-filter: blur(12px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 2px;

        flex: 1 1;
        padding: 2rem;

        &__title {
          font-family: Bebas Neue;
          color: #fff;
          font-size: 3rem;
          font-weight: normal;
          margin: 2rem 0 1rem 0;
        }

        &__info {
          font-size: 0.9rem;
          font-family: "Poppins", sans-serif;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 0;
            border-bottom: solid 1px rgba(255, 255, 255, 0.1);
            &.no-border {
              border-bottom: none;
            }

            .coin {
              display: flex;
              align-items: center;
              font-size: 1rem;
              font-weight: 600;

              img {
                width: 30px;
                height: 30px;
                margin: 0 0.5rem;
              }
            }

            .label {
              opacity: 0.7;
              a {
                color: $colorPrimary;
                font-weight: 500;
                span {
                  margin-right: 0.5rem;
                }
              }
            }

            .value {
              font-weight: 500;
            }
          }

          .view-all {
            cursor: pointer;
            text-decoration: underline;
            font-style: italic;
            font-size: 14px;
          }
        }

        &__tabs {
          margin-top: 3rem;
          font-family: "Poppins", sans-serif;
          font-size: 1rem;
          font-weight: bold;

          .total-withdraw {
            height: 55px;
            margin: auto;
            text-align: center;
            margin: 3rem 0 1rem 0;

            span {
              opacity: 0.7;
              font-weight: normal;
              margin-right: 1rem;
            }
          }

          ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .tab {
            flex: 1 1;
            text-align: center;
            border-bottom: solid 1px rgba(255, 255, 255, 0.1);
            padding-bottom: 0.5rem;
            cursor: pointer;
          }

          .active {
            border-bottom: solid 2px $colorPrimary;
          }
          .desc-withdraw {
            text-align: center;
          }
        }

        &__input {
          margin: 3rem 0 1rem 0;
          padding: 0.5rem;
          background: #21252e;
          border: 1px solid rgba(85, 85, 85, 0.7);
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          height: 55px;

          input {
            width: 80%;
            margin-right: 1rem;
            background-color: transparent;
            border: none;
            outline: none;
            text-align: center;
            font-size: 1.3rem;
          }

          ::placeholder {
            color: #fff;
            font-size: 1.3rem;
          }
        }

        &__btn {
          display: flex;

          .ant-btn {
            height: unset;
          }

          button:disabled {
            pointer-events: none;
            background-color: gray !important;
          }

          .btn-wrapper {
            flex: 1 1;

            button {
              width: 100%;
            }
          }

          button {
            flex: 1 1;
            color: #101b1e;
            font-family: "Poppins", sans-serif;
            font-weight: bold;
            font-size: 0.9rem;
            background: $colorPrimary;
            border-radius: 6px;
            border: none;
            padding: 0.8rem;
            margin-top: 1rem;

            &:first-child {
              margin-right: 0.5rem;
            }

            &:last-child {
              margin-left: 0.5rem;
            }
          }
        }
      }

      // .box-left {
      //   margin-right: 1rem;
      // }

      .box-right {
        margin-left: 1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .token-vault {
    &__info {
      flex-direction: column-reverse;
      .info {
        margin-left: 0;
        margin-bottom: 1rem;
      }
      .chart {
        margin-top: 2rem;
        div {
          height: 500px !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .token-vault {
    &__main {
      .boxes {
        flex-direction: column;
        .box-left {
          margin-right: 0;
          margin-bottom: 2rem;
        }

        .box-right {
          margin-left: 0;
        }
      }
    }
    &__info {
      .info {
        &__description {
          .iheader {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          padding: 1rem;
          background: linear-gradient(
              0deg,
              rgba(3, 9, 60, 0.3) 12.36%,
              rgba(19, 23, 55, 0) 100%
            ),
            rgba(0, 0, 0, 0.1);
          border: 1px solid #383743;
          // border-top: 2px solid #0083fc;
          box-sizing: border-box;
          backdrop-filter: blur(12px);
          border-radius: 2px;
          .title {
            font-size: 2rem;
            text-align: left;
          }
          .content {
            background: linear-gradient(
                0deg,
                rgba(3, 9, 60, 0.3) 12.36%,
                rgba(19, 23, 55, 0) 100%
              ),
              rgba(0, 0, 0, 0.1);
            backdrop-filter: unset;
            background: unset;
            border: none;
          }
        }
      }
    }
  }
}

@media (max-width: 556px) {
  .token-vault {
    &__info {
      .info__locktime .countdown div:last-child {
        font-size: 1rem !important;
      }
      .chart-tvl {
        .tvl ul li {
          flex-direction: column;

          span {
            margin: 0.2rem 0;
          }
        }
      }
    }
  }
}
