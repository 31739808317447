.dashboard {
  padding-top: 150px;

  &__main_content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .infotab {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;
    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #4e525b;
      width: 60%;
      margin-bottom: 2rem;
      cursor: pointer;
      white-space: nowrap;
    }

    &__item {
      padding: 0rem 2rem 1rem 2rem;
      font-size: 0.8rem;

      &--active {
        border-bottom: 2px solid $colorPrimary;
        margin-bottom: -2px;
        font-weight: bold;
        font-size: 1rem;
      }
    }
    &__body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      padding: 0 2rem;

      .body-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.5rem;

        img {
          margin-right: 0.5rem;
          width: 48px;
          height: 48px;
        }

        &__content {
          .title {
            font-size: 0.75rem;
            white-space: nowrap;
          }
          .value {
            font-size: 1.5rem;
            font-weight: 600;
            span {
              font-size: 1rem;
              font-weight: 500;
              opacity: 0.5;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }

  .recently-listed,
  .recently-sold {
    cursor: pointer;
    flex: 1 1;
    width: 100%;
    margin: 3rem 2rem 3rem 0;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .content {
      background: rgba(19, 22, 38, 0.4);
      border: 1px solid #4e525b;
      border-radius: 4px;
      backdrop-filter: blur(12px);
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      border-bottom: 1px solid #4e525b;
      box-sizing: border-box;
      &:last-child {
        border-bottom: none;
      }

      .icon {
        margin-right: 1rem;
        background-color: transparent;
        border: 3px solid $colorPrimary;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        overflow: hidden;
        position: relative;

        .rifi-player {
          width: 160px;
          height: 160px;
          left: -13px;
          top: 6px;
          position: absolute;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .properties {
        flex: 3 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .main-property,
        .skills {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .main-property {
          margin-bottom: 0.5rem;

          &__id {
            background: #2192e4;
            padding: 0.2rem;
            border-radius: 4px;
            white-space: nowrap;
          }

          &__item {
            font-weight: 400;
            font-size: 0.8rem;
            white-space: nowrap;
            padding: 0 0.5rem;
            span {
              font-weight: 600;
            }
          }
        }

        .skills {
          .skill {
            background: #21252e;
            border: 1px solid rgba(85, 85, 85, 0.7);
            box-sizing: border-box;
            width: 100%;
            text-align: center;
            padding: 0.3rem;

            div:first-child {
              text-transform: uppercase;
              font-weight: 600;
              font-size: 0.625rem;
              color: #ffffff;
              opacity: 0.5;
            }

            div:last-child {
              font-weight: 600;
              font-size: 0.8rem;
              color: #fff;
            }
          }
        }
      }
      .price {
        flex: 1.5 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0.5rem;

        &__title {
          text-transform: uppercase;
          font-size: 0.75rem;
          opacity: 0.5;
        }

        &__money {
          font-weight: 500;
          font-size: 1.25rem;
          text-align: center;
        }

        &__fiat {
          font-weight: 500;
          font-size: 0.9rem;
          opacity: 0.5;
          text-align: center;
        }

        &__time {
          font-size: 0.6rem;
          opacity: 0.5;
        }
      }
    }
    .view-more-btn {
      cursor: pointer;
      color: $colorPrimary;
      font-size: 0.85rem;
      height: 32px;
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pagination {
      margin: 1rem 0;
      justify-content: center;
    }
  }
}

@media (max-width: 992px) {
  .dashboard {
    .infotab {
      &__header,
      &__body {
        width: 100%;
      }
    }

    &__main_content {
      flex-direction: column;

      .recently-listed {
        margin: 2rem 0 1rem 0;
      }

      .recently-sold {
        margin: 0 0 1rem 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard {
    .infotab {
      &__body {
        padding: 0;
      }
    }
  }
}

@media (max-width: 576px) {
  .dashboard {
    &__main_content {
      flex-direction: column;
      .recently-listed,
      .recently-sold {
        &__item {
          flex-direction: column;
          width: 100%;
          overflow: auto;

          .properties {
            padding: 1rem 0;
          }

          .price {
            min-width: 120px;
          }

          &::-webkit-scrollbar {
            width: 1rem;
            height: 0.2rem;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }

          &::-webkit-scrollbar-thumb {
            background-color: #888787;
            outline: 1px solid #24232d;
          }
        }
        .pagination {
          .page-item {
            width: 16px;
            height: 16px;

            .btn {
              font-size: 0.9rem;
            }
          }

          .prev-page {
            padding-right: 0;
          }

          .prev-page,
          .next-page {
            height: unset;
            font-size: 0.9rem;
          }
        }
      }
    }
    .infotab {
      &__item {
        padding: 0 1rem;
      }

      &__body {
        padding-bottom: 1rem;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 1rem;
          height: 0.2rem;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #888787;
          outline: 1px solid #24232d;
        }
      }
    }
  }
}
