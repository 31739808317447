.nft-box-detail {
  padding: 150px 0;
  background: url("/assets/images/detail_footballer/banner.png");
  background-size: contain;
  background-repeat: no-repeat;
  @media screen and (min-width: 2000px) {
    background-size: 100% 100%;
  }
  .box-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;
    .avatar {
      position: relative;
      .img-bg {
        width: 100%;
        max-width: 400px;
      }
      .img-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 320px;
        @media screen and (max-width: 576px) {
          min-width: 200px;
        }
      }
    }
    .price_box {
      text-align: center;
      .title {
        font-size: 14px;
        padding-bottom: 8px;
      }
      .price {
        font-size: 18px;
        color: #fcb100;
        font-weight: bold;
        font-style: italic;
      }
    }
    .actions {
      padding-top: 10px;
      .app-btn {
        margin: 0px 5px;
      }
    }
  }
  .box-info {
    .info {
      background: linear-gradient(
          0deg,
          rgba(3, 9, 60, 0.3) 12.36%,
          rgba(19, 23, 55, 0) 100%
        ),
        rgba(0, 0, 0, 0.1);
      border: 0px 1px 1px 1px solid #383743;
      border-top: 2px solid #0083fc;
      border-radius: 2px;
      backdrop-filter: blur(12px);
      padding: 30px 0px;
      text-align: center;
      .name {
        color: #ffffff;
        text-transform: uppercase;
        font-size: 48px;
        font-family: "Bebas Neue";
      }
      .box_id {
        .label {
          opacity: 0.6;
        }
        color: #ffffff;
      }
    }
    .description {
      padding-top: 68px;
      .title {
        text-align: center;
        text-transform: uppercase;
        font-size: 48px;
        font-family: "Bebas Neue";
      }
      &__content {
        background: linear-gradient(
            0deg,
            rgba(3, 9, 60, 0.3) 12.36%,
            rgba(19, 23, 55, 0) 100%
          ),
          rgba(0, 0, 0, 0.1);
        border: 0px 1px 1px 1px solid #383743;
        border-top: 2px solid #0083fc;
        border-radius: 2px;
        backdrop-filter: blur(12px);
        padding: 30px 10px;
      }
    }
  }
  .sale-history {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-family: UTM Bebas;
      font-weight: normal;
      font-size: 3rem;
      line-height: 3;
      color: #ffffff;
      @media screen and(max-width:700px) {
        font-size: 1.5rem;
      }
    }
    &__events {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 0.5rem;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      button {
        height: 40px;

        &:first-child {
          margin-right: 0.3rem;
          width: 150px;
          border: 1px solid rgba(253, 220, 122, 0.7);
          border-radius: 6px;
          background-color: transparent;
          color: #fddc7a;
          font-size: 0.9rem;
          font-weight: bold;
        }

        &:last-child {
          margin-left: 0.3rem;
          width: 150px;
          border: 1px solid rgba(253, 220, 122, 0.7);
          border-radius: 6px;
          background-color: $colorPrimary;
          color: #101b1e;
          font-size: 0.9rem;
          font-weight: bold;
        }
      }
    }

    .offer-table,
    .sale-table {
      width: 100%;
      max-width: 1200px;
      background: rgba(16, 25, 48, 0.27);
      border: 1px solid #24232d;
      box-sizing: border-box;
      backdrop-filter: blur(12px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 2px;

      .rt-td {
        text-align: center;
      }

      .rt-thead {
        padding: 0.6rem;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        border: 1px solid #24232d;
        background: rgba(16, 25, 48, 0.27);

        .rt-tr {
          border: none;
          align-items: center;
        }

        .rt-th {
          box-shadow: none;
        }
      }

      .rt-tbody {
        overflow: hidden;

        .rt-tr-group:hover {
          background: #183047;
        }

        .rt-tr {
          align-items: center;
        }
      }

      .rt-tr {
        padding: 0.3rem;
        border-bottom: 1px solid #24232d;
      }

      .rt-table {
        &::-webkit-scrollbar {
          width: 1rem;
          height: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #888787;
          outline: 1px solid #24232d;
        }
      }
    }
  }
}
