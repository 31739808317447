.pagination {
  justify-content: flex-end;
  margin-top: 40px;
  @media screen and(max-width:576px) {
    justify-content: center;
  }

  .prev-page,
  .next-page {
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 8px 12px;
    text-transform: uppercase;
    border: solid 1px transparent;
    width: unset;
    height: 32px;
    &:hover {
      border: solid 1px rgba(255, 255, 255, 0.1);
    }
    &.disable {
      // background-color: #5d5d5d;
      // border-radius: 50%;
      color: rgba($color: white, $alpha: 0.5);
      cursor: not-allowed;
      .icon-page {
        path {
          stroke: $colorWhite;
          opacity: 0.4;
        }
      }
    }
    &.active {
      //   background: $colorPrimary400;
      .icon-page {
        path {
          stroke: $colorWhite;
        }
      }
    }
  }

  .page-item {
    // margin: 0px 4px;
    width: 32px;
    height: 32px;
    text-align: center;
    .btn {
      font-size: 14px;
      color: $colorNeutral400;
      background-color: transparent;
      border-color: transparent;
      // margin-right: 10px;
      text-align: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
      &:active {
        background-color: transparent;
        border-color: transparent;
      }
    }
    &.active {
      .btn {
        color: $colorPrimary;
        font-weight: 800;
      }
    }
    &:hover {
      .btn {
        color: $colorPrimary;
      }
    }
  }
}
