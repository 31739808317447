.layout-wrapper {
  position: relative;
  .header {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    .nav {
      padding: 32px 0;
      align-items: e;
      .logo {
        padding: 0;
        margin-right: 100px;
        img {
          cursor: pointer;
        }
      }
      .nav-link {
        margin-right: 44px;
        color: white;
        &:hover {
          color: white;
        }
        &.active {
          color: white;
          font-weight: 700;
        }
      }

      .account {
        background: #272727;
        margin-left: auto;
        padding: 10px 24px;
        border-radius: 20px;
        font-weight: bold;

        .account-address {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;

          svg {
            margin-left: 0.3rem;
          }
        }

        .disconnect-wallet {
          position: absolute;
          top: 40px;
          right: -20px;
          border-radius: 20px;
          width: 200px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          button {
            background-color: #272727;
            border-radius: 20px;
            padding: 10px 15px;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            border: none;
          }
        }

        &.account-info {
          display: flex;
          font-weight: 500;

          .account-balance-ru {
            display: flex;
            margin-right: 14px;

            .coin-icon {
              margin: 0 6px;
              width: 24px;
              height: 24px;
            }

            img {
              cursor: pointer;
            }
          }
        }

        &.connect-btn {
          cursor: pointer;
          &:hover {
            background: darken($color: #272727, $amount: 5);
          }
        }
        &.wrong {
          background: rgb(255, 67, 67);
          &:hover {
            background: rgb(255, 67, 67);
          }
        }
      }
    }
  }
  .body {
    min-height: 100vh;
  }

  .footer {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    min-height: 106px;
    padding: 36px 0;
    border-top: 1px solid #ffffff;
    .footer-wrapper {
      justify-content: space-between;

      .contact {
        .social-link {
          ul {
            height: 50px;
            list-style: none;
            margin: unset;
            padding: unset;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          li a {
            background: rgba(45, 51, 75, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;
            border-radius: 8px;
            padding: 1rem 1.5rem;
            margin: 0 0.5rem;
          }
        }
      }

      .copyright {
        text-align: center;
      }
    }
  }

  @media (max-width: 992px) {
    .header {
      .nav {
        .nav-link {
          margin-right: 0;
        }
      }
    }
    .footer {
      .footer-wrapper {
        justify-content: center;
        flex-direction: column;
        .copyright {
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .footer {
      .footer-wrapper {
        .contact {
          .social-link {
            li a {
              padding: 0.5rem 0.75rem;
            }
          }
        }
      }
    }
  }
}
