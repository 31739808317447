.modal-form {
  .title {
    font-weight: bold;
    font-size: 24px;
  }
  .type-sell-block {
    padding: 22px 0px;
    border-bottom: 1px solid rgba($color: $colorWhite, $alpha: 0.2);
    .select-type-sell {
      .ant-radio-wrapper {
        color: $colorWhite;
        .ant-radio {
          &:hover {
            .ant-radio-inner {
              border-color: $colorPrimary;
            }
          }
        }
        .ant-radio-inner {
          background-color: transparent;
        }
        &-checked {
          font-weight: bold;
          .ant-radio-inner {
            border-color: $colorWhite;
            background-color: transparent;
            &::after {
              background-color: $colorPrimary;
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    border-top: none;
  }
  &__content {
    padding-bottom: 4px;
    .description {
      padding-top: 18px;
      padding-bottom: 20px;
    }
    .form-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .form-app {
      .ant-form-item-label {
        text-align: left;
        label {
          color: $colorWhite;
        }
      }
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-input-affix-wrapper {
            background-color: transparent;
            border: 1px solid rgba($color: $colorWhite, $alpha: 0.3);
            border-radius: 6px;
            input {
              background: transparent;
              color: $colorWhite;
              border: none;
              text-align: right;
              border-radius: 0px;
              &:hover {
                background: transparent;
              }
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 10000px #1b1b1b inset !important;
              -webkit-text-fill-color: $colorWhite;
              caret-color: $colorWhite;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
            .ant-input-suffix {
              color: $colorWhite;
              font-weight: bold;
            }
          }
          .ant-input-number {
            background-color: transparent;
            border: 1px solid rgba($color: $colorWhite, $alpha: 0.3);
            border-radius: 6px;
            color: white;
            .ant-input-number-handler-wrap {
              background-color: transparent;
              .ant-input-number-handler {
                color: white;
                border-color: rgba($color: $colorWhite, $alpha: 0.3);
                .ant-input-number-handler-up-inner,
                .ant-input-number-handler-down-inner {
                  color: white;
                }
                &:hover {
                  .ant-input-number-handler-up-inner,
                  .ant-input-number-handler-down-inner {
                    color: rgba(252, 177, 0, 1);
                  }
                }
              }
            }
          }
        }
      }
      .result-box {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 18px;
        .content {
          text-align: right;
          width: 40%;
          color: rgba($color: $colorWhite, $alpha: 0.5);
        }
      }
      .group-btn-box {
        padding-top: 28px;
        border-top: 1px solid rgba($color: $colorWhite, $alpha: 0.2);
        display: grid;
        grid-template-columns: auto auto;
        @media screen and(max-width:576px) {
          grid-template-columns: auto;
          gap: 10px;
        }
        button {
          margin: 0px 5px;
        }
      }
    }
  }
}
