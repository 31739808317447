.marketplace-wrapper {
  .banner {
    position: relative;
    padding-top: 40.27%;
    width: 100%;
    background: url("../images/marketplace/banner_marketplace.png");
    background-size: cover;
    background-repeat: no-repeat;
    .title {
      position: absolute;
      bottom: 30%;
      width: 100%;
      text-align: center;
      img {
        margin-bottom: 20px;
      }
    }
    .tab-marketplace {
      background: #000000;
      border-radius: 4px;
      width: fit-content;
      margin: 0 auto;
      display: flex;
      white-space: nowrap;
      .tab-item {
        padding: 8px 20px;
        min-width: 200px;
        color: #fca702;
        text-align: center;
        cursor: pointer;
        line-height: 24px;
        transition: all 0.2s linear;
        @media screen and(max-width:576px) {
          min-width: 150px;
        }
        @media screen and(max-width:476px) {
          min-width: 100px;
        }
        @media screen and(max-width:340px) {
          padding: 6px 6px;
        }
        &.active {
          background: #fca702;
          border-radius: 4px;
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }

  .marketplace-list {
    // margin-top: 48px;
    margin-bottom: 48px;
    position: relative;
    min-height: 400px;
    .marketplace-box {
      background: transparent;
      padding-bottom: 60px;
      margin-top: -70px;
      @media screen and (max-width: 992px) {
        margin-top: -50px;
      }
      @media screen and (max-width: 768px) {
        margin-top: -30px;
      }
      @media screen and (max-width: 576px) {
        margin-top: 10px;
      }

      .list-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .info-box {
          padding: 30px;
          margin: 0px 10px;
          min-width: 200px;
          @media screen and (max-width: 768px) {
            padding: 10px;
            text-align: center;
          }
          .label {
            padding-bottom: 5px;
          }
          .value {
            font-weight: bold;
            font-size: 20px;
            &.time-remaining {
              color: $colorPrimary;
            }
            @media screen and (max-width: 992px) {
              font-size: 18px;
            }
          }
        }
      }
      .description {
        text-align: center;
        color: rgba($color: #ffffff, $alpha: 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .text-nomal {
          padding-right: 4px;
        }
        .title {
          color: #ffffff;
          padding-left: 4px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .number {
          font-weight: bold;
          padding-left: 4px;
        }
        .Bronze {
          color: #418bdd;
        }
        .Silver {
          color: #cf8853;
        }
        .Gold {
          color: #ffff64;
        }
      }
    }

    .marketplace-item {
      display: flex;
      justify-content: center;
      margin-bottom: 300px;
      .card.box-card {
        height: 390px;
        width: 100%;
        max-width: 254px;
        position: relative;
        background-color: transparent !important;
        .card-box-shadow {
          cursor: pointer;
          .card-shadow {
            position: absolute;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 280px;
            top: 50%;
            left: 50%;
            transition: all 0.3s;
          }
          &:hover {
            .card-shadow {
              box-shadow: 0px 4px 68px 35px rgba(252, 177, 0, 0.39);
            }
          }
          .card-body {
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            position: relative;
            padding: 12px;
            min-height: 388px;

            &.card-level-1 {
              background-image: url("../images/marketplace/card-bg-1.png");
              // &:hover {
              //   background-image: url("../images/marketplace/card-bg-hover-1.png");
              // }
            }
            &.card-level-2 {
              background-image: url("../images/marketplace/card-bg-2.png");
              // &:hover {
              //   background-image: url("../images/marketplace/card-bg-hover-2.png");
              // }
            }
            &.card-level-3 {
              background-image: url("../images/marketplace/card-bg-3.png");
              // &:hover {
              //   background-image: url("../images/marketplace/card-bg-hover-3.png");
              // }
            }
            .card-name {
              width: fit-content;
              font-size: 32px;
              line-height: 40px;
              text-align: center;
              position: absolute;
              transform: translateX(-50%);
              text-transform: uppercase;
              bottom: 44px;
              left: 50%;
            }

            .card-content {
              height: 89%;
              display: flex;
              align-items: center;
              justify-content: center;

              .card-img {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  position: absolute;
                }
                .ball-bg {
                  mix-blend-mode: screen;
                }
              }
            }
          }
        }
        .card-info {
          margin-top: 8px;
          text-align: center;
          // font-size: 14px;
          .description {
            margin-bottom: 12px;
            &.info-box {
              margin-top: 10px;
              opacity: 0.7;
              text-align: justify;

              .level-1 {
                color: #418bdd;
              }
              .level-2 {
                color: #cf8853;
              }
              .level-3 {
                color: #ffff64;
              }
            }
            .level {
              font-weight: bold;
              cursor: pointer;
            }
          }
          .number-player {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
          }
          .price {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: $colorPrimary;
            margin-top: 4px;
          }
          .buy-btn {
            margin-top: 20px;
            &:hover ~ .card-info ~ .card-box-shadow > .card-shadow {
              box-shadow: 0px 4px 68px 35px rgba(252, 177, 0, 0.39);
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .banner {
      padding-top: 78%;
      background-position: 50%;
      .title {
        bottom: 5%;
        img {
          width: 150px;
        }
      }
    }
  }
}

.marketplace-select-currency {
  .title {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .ant-radio-group {
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: white;

      .ant-radio-inner {
        background-color: transparent;
        border-color: white;
      }

      .ant-radio-checked .ant-radio-inner {
        &:after {
          background-color: $colorPrimary;
        }
      }
    }
  }
}

.app-modal {
  .ant-modal-content {
    .open-box-result-body {
      margin-left: -40px;
      margin-right: -40px;
      .title {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
      }
      .message {
        font-size: 14px;
        text-align: center;
        padding: 12px 48px;
      }

      .result-slider {
        .slider-item {
          width: 354px !important;
          .item-wrapper {
            padding-left: 50px;
            padding-right: 50px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
            }
          }
        }
        .slick-arrow {
          z-index: 999;
          // color: white;
          // &::before {
          //   color: transparent;
          // }
          &.slick-prev {
            left: calc((100% - 354px) / 2);
          }
          &.slick-next {
            right: calc((100% - 354px) / 2);
          }
        }
      }
    }
  }

  &.gold {
    .ant-modal-content {
      padding: 0;
      background-image: url("../images/marketplace/openbox-bg-gold.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 0;
      padding-top: 60.416%;
      border: none;
      @media (max-width: 576px) {
        background-image: url("../images/marketplace/openbox-bg-gold-mobile.png");
        padding-top: 177.86%;
      }

      .open-box-result-body {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: 576px) {
          display: block;
        }

        .box-title {
          display: none;
          @media (max-width: 576px) {
            display: block;
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
            padding-top: 10%;
            margin-bottom: 33px;
          }
          @media (max-width: 400px) {
            margin-bottom: 13px;
          }
        }

        .box-left {
          display: flex;
          .player-stats {
            position: absolute;
            left: 60px;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width: 1000px) {
              left: 30px;
            }
            @media (max-width: 576px) {
              position: static;
              transform: unset;
              padding-left: 36px;
            }
            @media (max-width: 400px) {
              padding-left: 30px;
            }
          }
          .player-img {
            position: absolute;
            width: fit-content;
            left: 16%;
            top: 16%;
            .rifi-player {
              width: 32.3vw;
              max-width: 323px;
              padding-top: 141.8%;
              position: relative;
              rifi-player {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }
            .player-legendary {
              width: 380px;
            }
            &__confetti {
              position: absolute;
              left: -10%;
              top: -16%;
              transform: scaleY(1.3);
            }

            @media (max-width: 1000px) {
              left: 15%;
              top: 10%;
              &__confetti {
                width: 34.3vw;
                max-width: 323px;
                left: 0;
                top: -5%;
              }
            }
            @media (max-width: 992px) {
              .player-legendary {
                width: 390px;
              }
            }
            @media (max-width: 868px) {
              .player-legendary {
                width: 350px;
              }
            }
            @media (max-width: 768px) {
              .player-legendary {
                width: 330px;
              }
            }
            @media (max-width: 676px) {
              .player-legendary {
                width: 280px;
              }
            }
            @media (max-width: 576px) {
              position: relative;
              left: -8%;
              text-align: center;
              .rifi-player {
                width: 52vw;
                rifi-player {
                  top: 4%;
                  left: 25%;
                }
              }
              .player-legendary {
                overflow: hidden;
                width: 100%;
              }

              &__confetti {
                width: 100%;
                top: 4%;
                left: 5%;
              }
            }
            @media (max-width: 400px) {
              padding-top: 9%;
              left: 0%;
              .rifi-player {
                rifi-player {
                  top: 0%;
                  left: 0%;
                }
              }
              &__confetti {
                width: 100%;
                top: 4%;
                left: -4%;
              }
            }
          }
          .player-stats__top {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .player-name {
            font-family: UTM Bebas;
            font-size: 24px;
            line-height: 40px;
            text-shadow: 0px 4px 13px rgba(0, 0, 0, 0.45);
            margin-bottom: 9px;
            white-space: nowrap;
            @media (max-width: 1000px) {
              font-size: 20px;
              line-height: 26px;
              margin-bottom: 11px;
            }
          }
          .player-id {
            background: #2192e4;
            border-radius: 2px;
            padding: 4px 8px;
            font-size: 14px;
            line-height: 20px;
            width: fit-content;
            span {
              opacity: 0.6;
            }
            @media (max-width: 1000px) {
              font-size: 12px;
              padding: 0px 8px;
            }
          }
          .player-overall {
            font-size: 20px;
            line-height: 28px;
            margin: 35px 0 32px 0;
            text-align: center;
            span {
              font-weight: 600;
              font-size: 52px;
              line-height: 40px;
              letter-spacing: 0.02em;
              position: relative;
              top: 8px;
            }
            @media (max-width: 1000px) {
              font-size: 18px;
              line-height: 24px;
              margin: 28px 0 24px 0;
              span {
                font-size: 46px;
                line-height: 36px;
                top: 5px;
              }
            }
            @media (max-width: 576px) {
              font-size: 14px;
              line-height: 20px;
              margin: 28px 0 24px 0;
              span {
                font-size: 37px;
                line-height: 28px;
                top: 5px;
              }
            }
            @media (max-width: 400px) {
              margin: 20px 0 16px 0;
              font-size: 13px;
              span {
                font-size: 34px;
              }
            }
          }
          .player-stats__bottom {
            width: fit-content;
            margin: 0 auto;
          }
          .block-line {
            opacity: 0.3;
            border-top: 1px solid #ffffff;
            margin: 12px 5px;
          }
          .info-block {
            text-align: center;
            line-height: 20px;
            .info-name {
              font-size: 12px;
              opacity: 0.6;
            }
            .info-value {
              font-weight: bold;
              font-size: 16px;
            }
          }
          @media (max-width: 400px) {
            .block-line {
              margin: 8px 5px;
            }
            .info-block {
              .info-name {
                font-size: 11px;
              }
              .info-value {
                font-size: 14px;
              }
            }
          }
        }

        .box-right {
          padding-right: 55px;

          @media (max-width: 1000px) {
            padding-right: 35px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          @media (max-width: 576px) {
            padding-right: 0;
            align-items: center;
          }

          &__top {
            padding-top: 90px;
            h3 {
              font-weight: 600;
              font-size: 28px;
              line-height: 40px;
              color: #ffffff;
              margin-bottom: 13px;
              text-align: center;
            }

            @media (max-width: 1000px) {
              padding-top: 0px;
              h3 {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 10px;
              }
            }
            @media (max-width: 576px) {
              h3 {
                display: none;
              }
            }
          }
          &__middle {
            max-width: 300px;
            padding: 28px 0 70px 0;
            .player-card {
              display: flex !important;
              flex-direction: column;
              align-items: center;
            }
            .player-avatar {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.2);
              border: 4px solid $colorPrimary;
              overflow: hidden;
              position: relative;
              .rifi-player {
                width: 160px;
                height: 227px;
                position: absolute;
                top: 5px;
                left: -50%;
              }
            }
            .player-name {
              font-family: UTM Bebas;
              font-size: 20px;
              line-height: 40px;
              letter-spacing: 0.02em;
              padding-top: 2px;
              white-space: nowrap;
            }
            .player-id {
              background: #2192e4;
              border-radius: 2px;
              width: fit-content;
              padding: 0 8px;
              padding-top: 2px;
              margin-bottom: 8px;

              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              span {
                opacity: 0.8;
              }
            }
            .player-info {
              font-size: 13px;
              line-height: 24px;
              span {
                font-weight: bold;
              }
            }
            .slick-arrow {
              &::before {
                content: "";
                border: solid white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                opacity: 1;
                border-radius: 2px;
              }
            }
            .slick-prev {
              top: 115%;
              left: 40%;
              &::before {
                transform: rotate(135deg);
              }
            }
            .slick-next {
              top: 115%;
              right: 40%;
              &::before {
                transform: rotate(-45deg);
              }
            }

            @media (max-width: 1000px) {
              padding: 20px 0 50px 0;
              .player-avatar {
                width: 66px;
                height: 66px;
                .rifi-player {
                  width: 132px;
                  height: 187px;
                }
              }
              .player-name {
                font-size: 18px;
                line-height: 36px;
              }
              .player-id {
                font-size: 12px;
                line-height: 20px;
              }
              .player-info {
                font-size: 12px;
                line-height: 20px;
              }
            }
            @media (max-width: 576px) {
              padding: 24px 0 27px 0;
              .slick-arrow {
                // display: none !important;
              }
              .slick-prev {
                top: 50%;
                left: -10px;
              }
              .slick-next {
                top: 50%;
                right: -10px;
              }
              .player-avatar {
                position: relative;
              }
              .player-name {
                padding-top: 9px;
              }
              .player-id {
                position: absolute;
                z-index: 10;
                top: 57px;
              }
              .player-info {
                opacity: 0.7;
              }
            }
            @media (max-width: 400px) {
              padding: 8px 0;
              .slick-prev {
                left: 0;
              }
              .slick-next {
                right: 0;
              }
            }
          }
          &__bottom {
            display: flex;
            justify-content: center;
            button {
              background: $colorPrimary;
              border-radius: 6px;
              padding: 8px 24px;
              border: none;

              font-weight: bold;
              font-size: 15px;
              line-height: 24px;
              color: #101b1e;
              letter-spacing: 0.02em;
              text-transform: uppercase;
            }

            @media (max-width: 1000px) {
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .ant-modal-content {
      // padding: 24px;
      .open-box-result-body {
        .message {
          padding: 12px 0;
        }

        .result-slider {
          .slider-item {
            width: 100% !important;
            .item-wrapper {
              padding-left: 0px;
              padding-right: 0px;
              width: 100%;
              display: flex !important;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.tooltip-box {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      color: #000000;
    }
  }
}
