.nft-vault {
  .token-vault__info {
    margin-top: 20px;
    .info {
      margin-left: 0;
      .content {
        height: unset;
        border-top: 2px solid #0083fc;
        .bold {
          font-weight: bold;
        }
      }
    }

    .tvl {
      padding: 0rem 1.5rem;
      border-radius: 2px;
      width: 50%;
      padding-left: 0px;

      ul {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        li {
          width: 100%;
          margin: 0.2rem 0;
          display: flex;
          align-items: center;

          .title {
            color: $colorPrimary;
            font-size: 1.6rem;
            text-align: center;
            font-weight: bold;
            @media (max-width: 576px) {
              font-size: 1.2rem;
            }
          }

          span {
            display: flex;
            align-items: center;
            text-align: center;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 25px;
            background: -webkit-linear-gradient(
              180deg,
              #ffffff 20.83%,
              #c6c4c9 41.86%,
              #ffffff 77.44%,
              #aeabb2 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            justify-content: flex-start;
            @media (max-width: 576px) {
              font-size: 1.2rem;
              color: #fff;
            }
            &.countup {
              margin-left: 60px;
              @media (max-width: 576px) {
                font-size: 1.2rem;
                color: #fff;
              }
            }

            .coin-icon {
              margin-left: 0.5rem;
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
  .boxes {
    .box-left {
      .btn-claim {
        font-weight: bold;
        background: #fcb100;
        border-radius: 6px;
        border: none;
        color: #101b1e;
        &:hover {
          background: darken($color: #fcb100, $amount: 5);
        }
      }
    }
    .box-right {
      .txt-error {
        color: #ff1125;
        font-size: 12px;
      }
    }
  }
  .deposit-withdraw-nft {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 22px;
    margin-top: 10px;
    .info-deposit {
      color: #fcb100;
    }
    .ant-checkbox-wrapper {
      color: #ffffff;
    }
    .view-all {
      cursor: pointer;
      text-decoration: underline;
      font-style: italic;
      font-size: 14px;
    }
  }
  .withdraw {
    .withdraw-nft {
      .ant-checkbox-wrapper {
        color: #ffffff;
      }
    }
  }
}
.nft-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: 675px;
  @media screen and (max-width: 768px) {
    transition: all 1s;
    max-height: 625px;
  }
  &.info-collapse {
    @media screen and (max-width: 768px) {
      transition: all 0.5s;
      max-height: 450px;
    }
  }
  .title {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  .ant-checkbox-group {
    width: 100%;
    .ant-checkbox-wrapper {
      position: relative;
      width: 100%;
      color: #ffffff;
      .ant-checkbox {
        position: absolute;
        width: 16px;
        left: 10%;
        z-index: 1000;
      }
      span {
        &:nth-child(2) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.modal-nft-vault {
  max-width: 987px;
  .title-info {
    font-weight: bold;
    &__item {
      padding-bottom: 24px;
    }
    .value {
      color: #fcb100;
      float: right;
    }
  }
  .action-modal {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
    .ant-checkbox-wrapper {
      color: rgba(255, 255, 255, 0.5);
      font-weight: bold;
      .ant-checkbox-inner {
        &::after {
          background-color: #fcb100;
        }
      }
    }
    .btn-reset {
      border: 1px solid #fcb100;
      border-radius: 6px;
      background: transparent;
      color: #fcb100;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .ant-modal-content {
    padding: 20px;
    @media screen and(max-width:342px) {
      padding: 5px;
    }
    .ant-modal-header {
      background: #1b1b1b;
      border-bottom: none;
      padding-left: 0;
      .ant-modal-title {
        color: #ffffff;
        border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);
        padding-bottom: 20px;
        .title-modal {
          font-size: 20px;
          font-weight: bold;
          color: #fcb100;
        }
      }
    }
    .ant-modal-body {
      height: 685px;
      .ant-collapse {
        background: transparent;
        color: #ffffff;
        border: none;
        .ant-collapse-item {
          border-bottom: none;
          padding-bottom: 10px;
          .ant-collapse-header {
            color: #ffffff;
            .title-info__item {
              padding-bottom: 0;
              font-weight: bold;
              .value {
                color: #fcb100;
                float: right;
              }
            }
          }
          .ant-collapse-content {
            background: transparent;
            color: #ffffff;
          }
        }
      }
      .wallet-item {
        padding-bottom: 20px;
      }
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .app-btn {
        @media screen and(max-width:768px) {
          min-width: 50%;
        }
      }
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7070bb;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7070bb;
  }
}
.ant-checkbox-wrapper {
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: $colorPrimary;
      }
    }
    .ant-checkbox-inner {
      background-color: black;
      border: 1px solid #343434;
      border-radius: 3px;
    }

    &.ant-checkbox-checked {
      &:after {
        border: none;
      }
      .ant-checkbox-inner {
        background: $colorPrimary;
      }
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          background: transparent;
        }
      }
    }
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: $colorPrimary;
    }
  }
}

.vault-wrapper {
  .banner {
    position: relative;
    width: 100%;
    .title {
      position: absolute;
      bottom: 30%;
      width: 100%;
      text-align: center;
      img {
        margin-bottom: 20px;
      }
    }
    .tab-marketplace {
      background: #000000;
      border-radius: 4px;
      width: fit-content;
      margin: 0 auto;
      display: flex;
      white-space: nowrap;
      .tab-item {
        padding: 8px 20px;
        min-width: 200px;
        color: #fca702;
        text-align: center;
        cursor: pointer;
        line-height: 24px;
        transition: all 0.2s linear;
        @media screen and(max-width:576px) {
          min-width: 150px;
        }
        @media screen and(max-width:476px) {
          min-width: 100px;
        }
        @media screen and(max-width:340px) {
          padding: 6px 6px;
        }
        &.active {
          background: #fca702;
          border-radius: 4px;
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }

  .marketplace-list {
    // margin-top: 48px;
    margin-bottom: 48px;
    position: relative;
    min-height: 400px;
    .marketplace-box {
      background: transparent;
      padding-bottom: 60px;
      margin-top: -70px;
      @media screen and (max-width: 992px) {
        margin-top: -50px;
      }
      @media screen and (max-width: 768px) {
        margin-top: -30px;
      }
      @media screen and (max-width: 576px) {
        margin-top: 10px;
      }

      .list-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .info-box {
          padding: 30px;
          margin: 0px 10px;
          min-width: 200px;
          @media screen and (max-width: 768px) {
            padding: 10px;
            text-align: center;
          }
          .label {
            padding-bottom: 5px;
          }
          .value {
            font-weight: bold;
            font-size: 20px;
            &.time-remaining {
              color: $colorPrimary;
            }
            @media screen and (max-width: 992px) {
              font-size: 18px;
            }
          }
        }
      }
      .description {
        text-align: center;
        color: rgba($color: #ffffff, $alpha: 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .text-nomal {
          padding-right: 4px;
        }
        .title {
          color: #ffffff;
          padding-left: 4px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .number {
          font-weight: bold;
          padding-left: 4px;
        }
        .Bronze {
          color: #418bdd;
        }
        .Silver {
          color: #cf8853;
        }
        .Gold {
          color: #ffff64;
        }
      }
    }

    .marketplace-item {
      display: flex;
      justify-content: center;
      margin-bottom: 300px;
      .card.box-card {
        height: 390px;
        width: 100%;
        max-width: 254px;
        position: relative;
        background-color: transparent !important;
        .card-box-shadow {
          cursor: pointer;
          .card-shadow {
            position: absolute;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 280px;
            top: 50%;
            left: 50%;
            transition: all 0.3s;
          }
          &:hover {
            .card-shadow {
              box-shadow: 0px 4px 68px 35px rgba(252, 177, 0, 0.39);
            }
          }
          .card-body {
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            position: relative;
            padding: 12px;
            min-height: 388px;

            &.card-level-1 {
              background-image: url("../images/marketplace/card-bg-1.png");
              // &:hover {
              //   background-image: url("../images/marketplace/card-bg-hover-1.png");
              // }
            }
            &.card-level-2 {
              background-image: url("../images/marketplace/card-bg-2.png");
              // &:hover {
              //   background-image: url("../images/marketplace/card-bg-hover-2.png");
              // }
            }
            &.card-level-3 {
              background-image: url("../images/marketplace/card-bg-3.png");
              // &:hover {
              //   background-image: url("../images/marketplace/card-bg-hover-3.png");
              // }
            }
            .card-name {
              width: fit-content;
              font-size: 32px;
              line-height: 40px;
              text-align: center;
              position: absolute;
              transform: translateX(-50%);
              text-transform: uppercase;
              bottom: 44px;
              left: 50%;
            }

            .card-content {
              height: 89%;
              display: flex;
              align-items: center;
              justify-content: center;

              .card-img {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  position: absolute;
                }
                .ball-bg {
                  mix-blend-mode: screen;
                }
              }
            }
          }
        }
        .card-info {
          margin-top: 8px;
          text-align: center;
          // font-size: 14px;
          .description {
            margin-bottom: 12px;
            &.info-box {
              margin-top: 10px;
              opacity: 0.7;
              text-align: justify;

              .level-1 {
                color: #418bdd;
              }
              .level-2 {
                color: #cf8853;
              }
              .level-3 {
                color: #ffff64;
              }
            }
            .level {
              font-weight: bold;
              cursor: pointer;
            }
          }
          .number-player {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
          }
          .price {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: $colorPrimary;
            margin-top: 4px;
          }
          .buy-btn {
            margin-top: 20px;
            &:hover ~ .card-info ~ .card-box-shadow > .card-shadow {
              box-shadow: 0px 4px 68px 35px rgba(252, 177, 0, 0.39);
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .banner {
      padding-top: 10%;
      background-position: 50%;
      .title {
        bottom: 5%;
        img {
          width: 150px;
        }
      }
    }
  }
}

.top {
  min-height: 700px;
}

.search {
  width: 95%;
  margin-top: 30px;
  text-align: right;
  // position: absolute;
  // top: 30px;
  // right: 6%;
  input {
    min-width: 500px;
    padding: 10px;
    color: black;
    border-radius: 5px;
    border: 1px solid #2a343e;
  }
}

.note {
  width: 95%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 5%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  .note-txt {
    font-style: italic;
  }
}

.top100 {
  display: flex;
  justify-content: center;
  position: relative;
  
  .table {
    // margin-top: 100px;
    border-radius: 5px !important;
    font-size: 16px;
    width: 90%;
    tr {
      th {
        padding: 15px 50px;
        background-color: #2a343e;
      }
      td {
        padding: 13px;
      }
      .index {
        text-align: center;
      }
    }
  }
  thead th {
    background-color: #E0E0E0;
  }
  thead th:first-child {
    // border-radius: 100px 0 0 100px;
    border: none !important;
    border-top-left-radius: 10px;
  }
  thead th:last-child {
    // border-radius: 0 100px 100px 0;
    border: none !important;
    border-top-right-radius: 10px;
  }
  
  /* remove border from th... */
  /* !important is only required in stack snippet, probably not in your production code */
  
  // .table .noborder {
  //   border: none !important;
  // }
  
  /* remove border from first tbody row... */
  .table > tbody > tr:first-child > td,
  .table > tbody > tr:first-child > th {
    border-top: none;
  }
}

