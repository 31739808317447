.txt--title {
  font-family: UTM Bebas;
  letter-spacing: 0.02em;
  text-shadow: 0px 0px 23px #ec8438;
}

.ant-slider {
  .ant-slider-rail {
    background-color: rgba($color: white, $alpha: 0.12);
    height: 2px;
  }
  .ant-slider-track {
    background-color: rgba($color: $colorPrimary, $alpha: 0.9);
    height: 3px;
  }
  &:hover {
    .ant-slider-rail {
      background-color: rgba($color: white, $alpha: 0.12) !important;
    }
    .ant-slider-track {
      background-color: rgba($color: $colorPrimary, $alpha: 0.9) !important;
    }

    .ant-slider-handle {
      &:not(.ant-tooltip-open) {
        border-color: rgba($color: $colorPrimary, $alpha: 0.8) !important;
      }
    }
  }
  .ant-slider-handle {
    background-image: url("../icons/slider_handle.png");
    width: 12px;
    height: 12px;
    border: none;
    &:focus {
      box-shadow: 0 0 0 5px rgba($color: $colorPrimary, $alpha: 0.12);
    }
  }
  .ant-slider-dot {
    width: 4px;
    height: 4px;
    top: -1px;
    background-color: #494949;
    border: none;

    &:first-child {
      margin-left: -2px;
    }

    &-active {
      background-color: $colorPrimary;
    }
  }
  .ant-slider-mark-text {
    color: rgba($color: white, $alpha: 0.5);
  }
}

.app-modal {
  .ant-modal-content {
    background: $colorModalBg;
    border: 1px solid #484439;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    color: white;

    .ant-modal-close {
      color: white;
    }

    .ant-modal-body {
      padding: 0;
      .message {
        font-size: 18px;
      }

      .actions {
        margin-top: 24px;
        text-align: center;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

.app-btn {
  min-width: 160px;
  min-height: 40px;
  border: 1px solid rgba($color: $colorPrimary, $alpha: 0.8);
  box-sizing: border-box;
  border-radius: 6px;
  background: $colorModalBg;
  color: rgba($color: $colorPrimary, $alpha: 0.8);
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  transition: all 0.1s;
  text-transform: uppercase;
  &[disabled] {
    border: 1px solid rgba($color: $colorPrimary, $alpha: 0.8);
    background: $colorModalBg;
    color: rgba($color: $colorPrimary, $alpha: 0.8);
  }
  &:hover,
  &:focus {
    background: $colorModalBg;
    border: 1px solid $colorPrimary;
    color: $colorPrimary;
    &[disabled] {
      background: $colorModalBg;
      border: 1px solid $colorPrimary;
      color: $colorPrimary;
    }
  }

  &__primary {
    background: rgba($color: $colorPrimary, $alpha: 0.8);
    color: rgba($color: $colorModalBg, $alpha: 0.8);
    &:hover,
    &:focus {
      background: $colorPrimary;
      color: $colorModalBg;
      border-color: $colorPrimary;
      &[disabled] {
        background: $colorPrimary;
        color: $colorModalBg;
        border-color: $colorPrimary;
      }
    }
    &[disabled] {
      background: rgba($color: $colorPrimary, $alpha: 0.8);
      color: rgba($color: $colorModalBg, $alpha: 0.8);
    }
  }
}

.select-group {
  display: flex;
  align-items: center;
  border: 1px solid #262626;
  border-radius: 4px;
  width: fit-content;
  padding: 4px 4px 4px 16px;

  .select-pretext {
    img {
      padding-right: 5px;
    }
  }

  .ant-select {
    font-size: 14px;
    line-height: 24px;
    color: rgba($color: white, $alpha: 0.5);
    .ant-select-selector {
      border: none;
      background-color: transparent;
      padding: 0 0 0 22px;
      min-width: 190px;
    }
    .ant-select-arrow {
      color: white;
    }
  }
  .ant-select-focused {
    &:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.maintenance {
  text-align: center;
  font-family: Bebas Neue;
  font-size: 48px;
  margin: 80px 0 100px 0;
  background: -webkit-linear-gradient(
    90deg,
    #ffffff 22%,
    #c6c4c9 41.86%,
    #ffffff 77.44%,
    #aeabb2 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  position: relative;
  &:before {
    content: "TEMPORARILY ClOSED FOR MAINTENANCE";
    position: absolute;
    text-shadow: 0px 0px 23px #ec8438;
    font-family: Bebas Neue;
    font-size: 48px;
    z-index: -1;
  }
}

@mixin skelaton {
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.5) 25%,
    rgba(129, 129, 129, 0.34) 37%,
    rgba(190, 190, 190, 0.5) 63%
  );
  background-size: 400% 100%;
  animation: loading 1.4s ease infinite;
}

.skeleton-custom {
  &.ant-skeleton-active {
    .ant-skeleton-avatar {
      @include skelaton;
    }
    .ant-skeleton-content {
      .ant-skeleton-paragraph {
        & > li {
          @include skelaton;
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  // border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ReactTable .rt-noData {
  background: transparent;
  color: $colorWhite;
  top: 75%;
}
