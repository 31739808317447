.card.wallet-card {
  cursor: pointer;
  width: 100%;
  max-width: 279px;
  position: relative;
  background-color: transparent !important;
  border: none;
  .card-top {
    width: 100%;
    height: 390px;
    position: relative;
  }
  .card-bottom {
    text-align: center;
    padding-top: 16px;
    .card-price {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #fca702;
      padding-top: 4px;
    }
    .skeleton-card-id {
      .ant-skeleton-content {
        .ant-skeleton-paragraph {
          margin-bottom: 0;
          li {
            margin: 0 auto;
            height: 21px;
          }
        }
      }
      &.skeleton-card-price {
        .ant-skeleton-paragraph {
          margin-bottom: 28px;
        }
      }
    }
  }
  .card-shadow {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 300px;
    top: 50%;
    left: 50%;
  }
  &:hover {
    .card-shadow {
      box-shadow: 0px 4px 68px 35px rgba(252, 177, 0, 0.39);
    }
  }

  .card-body {
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    position: relative;
    padding: 12px;
    padding-left: 16px;
    &.bronze {
      background-image: url("../images/wallet/bg_bronze.png");
    }
    &.silver {
      background-image: url("../images/wallet/bg_silver.png");
    }
    &.gold {
      background-image: url("../images/wallet/bg_gold.png");
    }
    &.legendary {
      background-image: url("../images/wallet/bg_legendary.png");
    }

    .card-flag {
      position: absolute;
      right: 25px;
      top: 47px;
      &__bg {
        width: 36px;
        height: 36px;
        background: #000000;
        mix-blend-mode: normal;
        border-radius: 50%;
        opacity: 0.4;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .card-name {
      width: fit-content;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      transform: translateX(-50%);
      bottom: 40px;
      left: 50%;
      .ant-skeleton-content {
        .ant-skeleton-paragraph {
          margin: 12px 0px;
        }
      }
    }

    .card-content {
      display: flex;
      position: absolute;
      z-index: 11;
    }

    .card-info {
      padding-top: 50px;
      padding-left: 13px;
      position: absolute;
    }

    .info-block {
      padding: 12px 0;
      text-align: center;
    }

    .block-line {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin: 0 3px;
    }

    .info-name {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .info-value {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
      margin: 0 auto;
      &.overall {
        background-image: url("../images/wallet/overall.png");
        width: 43px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        &.legendary {
          background-image: url("../images/wallet/overall-legendary.png");
          color: #faf5b6;
        }
      }
    }

    .rifi-player {
      min-width: 208px;
      height: 295px;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    .card-deco {
      position: relative;
      z-index: 10;
      img {
        position: absolute;
        // top: 25px;
        left: 0px;
        max-width: 100%;
      }
    }
  }

  .card-swap {
    display: flex;
    margin-top: 0.5rem;
    min-height: 40px;
    gap: 0.2rem;

    button {
      flex: 1 1;
      text-align: center;
      cursor: pointer;
      background: #fca702;
      border-radius: 4px;
      color: #000000;
      font-weight: bold;
      border: none;

      &:disabled {
        background-color: grey;
        pointer-events: none;
      }
    }
  }
}

.wallet-wrapper {
  .banner {
    position: relative;
    padding-top: 40.27%;
    width: 100%;
    background: url("../../assets/images/wallet/banner-player.png"),
      url("../../assets/images/wallet/banner-bg.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
    .title {
      position: absolute;
      bottom: 20%;
      width: 100%;
      text-align: center;

      img {
        margin-bottom: 1.5rem;
      }
    }

    .tab-wallet {
      background: #000000;
      border-radius: 4px;
      width: fit-content;
      margin: 0 auto;
      display: flex;
      white-space: nowrap;
      .tab-item {
        padding: 8px 20px;
        min-width: 200px;
        color: #fca702;
        text-align: center;
        cursor: pointer;
        line-height: 24px;
        transition: all 0.2s linear;
        @media screen and(max-width:576px) {
          min-width: 150px;
        }
        @media screen and(max-width:476px) {
          min-width: 100px;
        }
        @media screen and(max-width:340px) {
          padding: 6px 6px;
        }
        &.active {
          background: #fca702;
          border-radius: 4px;
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }

  .wallet-list {
    margin-top: 48px;
    margin-bottom: 48px;
    &.box {
      margin-top: 5px;
    }
    .total-player {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;
      font-weight: bold;
      .value {
        padding-left: 5px;
        color: $colorPrimary;
      }
    }
    .wallet-item {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
    }
    .list-box {
      .type_box {
        background: #000000;
        border-radius: 4px;
        width: fit-content;
        margin: 0 auto;
        display: flex;
        white-space: nowrap;
        margin-bottom: 50px;
        border: 1px solid #262626;
        padding: 10px 0px;
      }
      .tab-item {
        min-width: 100px;
        color: #ffffff;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
        line-height: 24px;
        transition: all 0.2s linear;
        border-right: 1px solid #262626;
        padding: 0px 32px;

        &:last-child {
          border-right: none;
        }
        @media screen and(max-width:576px) {
          min-width: 60px;
        }
        @media screen and(max-width:476px) {
          min-width: 50px;
        }
        @media screen and(max-width:340px) {
          padding: 0px 16px;
        }
        &.active {
          // background: #fca702;
          color: #fca702;
          font-weight: bold;
          opacity: 1;
        }
      }
      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        font-weight: bold;
        .onSale {
          display: flex;
          align-items: center;
          justify-content: center;
          .filter-name {
            margin-right: 20px;
          }
        }
        .total_box {
          display: flex;
          justify-content: center;
          font-weight: bold;
          .value {
            padding-left: 5px;
            color: $colorPrimary;
          }
          .tooltip-info {
            margin-left: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .col-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 576px) {
    .banner {
      padding-top: 60%;
      background-size: 200% 100%;
      .title {
        bottom: 5%;
        img {
          width: 150px;
        }
      }
    }
  }

  .wallet-sort {
    padding-bottom: 48px;
    display: flex;
    justify-content: flex-end;
  }
}

.tooltip-total-box {
  .ant-tooltip-inner {
    .tooltip-text {
      .number {
        color: #fca702;
        font-weight: bold;
      }
    }
  }
}
