.game-box-list {
  .wallet-sort {
    padding-bottom: 48px;
    display: flex;
    justify-content: end;
  }
  .col-item {
    display: flex;
    justify-content: center;
  }
}
