.filter-common {
  .filter__content {
    padding-top: 16px;
  }
  .filter-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    .filter-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .react-switch-handle {
    box-shadow: none !important;
  }

  .filter-block {
    + .filter-block {
      padding-top: 16px;
    }

    .filter-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 12px;
    }

    &.filter-header {
      display: flex;
      justify-content: space-between;
      h6 {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-transform: capitalize;
        color: white;
      }
      button {
        font-size: 14px;
        line-height: 24px;
        border: none;
        background-color: transparent;
        color: white;
      }
    }

    &.filter-type {
      .filter-group {
        background: #000000;
        border: 1px solid #262626;
        border-radius: 4px;
        padding: 7px;
        display: flex;
        cursor: pointer;
        // justify-content: space-around;

        div {
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          opacity: 0.5;
          flex: 1 1 50%;
          text-align: center;
          + div {
            border-left: 1px solid rgba(255, 255, 255, 0.3);
          }

          &[active="true"] {
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            color: $colorPrimary;
            opacity: 1;
          }
        }
      }
    }

    &.filter-checkbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        padding-left: 18px;

        label {
          flex: 1 1 50%;
          max-width: 50%;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.5);
          margin-right: 0;
        }

        // .ant-checkbox {
        //   padding-right: 8px;
        // }

        input[type="checkbox"] {
          visibility: hidden;
        }

        .ant-checkbox-inner {
          position: absolute;
          top: -18px;
          left: -18px;
          height: 20px;
          width: 20px;
          background-color: black;
          border: 1px solid #343434;
          border-radius: 1px;
        }

        .ant-checkbox-checked {
          &:after {
            border: none;
          }
          .ant-checkbox-inner {
            background: $colorPrimary;
          }
        }
      }
    }
    &.filter-select {
      display: flex;
      align-items: center;
      .filter-name {
        width: 20%;
        padding-bottom: 0;
      }
      .ant-select {
        width: 80%;
        color: #ffffff;
        .ant-select-selector {
          border: 1px solid #343434;
          background: transparent;
        }
        .ant-select-clear {
          background: transparent;
          img {
            background: #000000;
            width: 10px;
            height: auto;
          }
        }
      }
    }
  }
}

.dropdown-memu {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  background: rgba($color: #1e232d, $alpha: 1);

  .ant-select-item {
    color: rgba($color: #ffffff, $alpha: 0.5);
    &:hover {
      background: transparent;
      color: #ffffff;
    }
    &.ant-select-item-option-selected {
      color: #ffffff;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
    }
    &.ant-select-item-option-active:not(.ant-select-item-option-selected) {
      background: transparent;
    }
  }
}
