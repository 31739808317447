.talent-description-tooltip {
  font-size: 12px;
  .ant-tooltip-inner {
    background-color: #45464c;
  }
  .ant-tooltip-arrow .ant-tooltip-arrow-content {
    background-color: #45464c;
  }
}
.detail-footballer {
  margin-bottom: 5rem;
  .col-avatar {
    padding-bottom: 20px;
  }
  &__info {
    padding-top: 150px;
    // height: 800px;
    background: url("/assets/images/detail_footballer/banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 99;

    .info-wrapper {
      .avatar-player {
        position: relative;
        width: 100%;
        .ball {
          height: auto;
          width: 100%;
        }
        .legandary {
          position: absolute;
          mix-blend-mode: screen;
          left: 0;
          width: 100%;
          bottom: 50px;
          @media screen and(max-width:576px) {
            bottom: 50px;
          }
          @media screen and(min-width:576px) {
            bottom: 70px;
          }
          @media screen and(min-width:768px) {
            bottom: 90px;
          }
          @media screen and(min-width:992px) {
            bottom: 50px;
          }
          @media screen and(min-width:1200px) {
            bottom: 90px;
          }
        }
        .player-img {
          width: 75%;
          height: 527px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 5px;
          .player-legend {
            width: 100%;
          }
          .rifi-player {
            width: 100%;
            height: 100%;
          }
          @media screen and(max-width:576px) {
            height: 100%;
            // width: auto;
          }
          @media screen and(min-width:576px) {
            height: 477px;
          }
          @media screen and(min-width:768px) {
            height: 650px;
          }
          @media screen and(min-width:992px) {
            height: 427px;
          }
          @media screen and(min-width:1200px) {
            height: 500px;
          }
          @media screen and(min-width:1400px) {
            height: 600px;
          }
        }
      }

      .info-avatar {
        &__body {
          font-family: "Poppins", sans-serif;
          color: #ffffff;
          font-size: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          .description {
            font-size: 14px;
            text-align: justify;
            padding: 0 20px;
            margin-bottom: 16px;
          }

          .price {
            color: $colorPrimary;
            font-size: 1.5rem;
            font-weight: bold;
          }
          .title-auction {
            color: rgba($color: $colorWhite, $alpha: 0.3);
            text-align: center;
          }
          .price-box {
            width: 65%;
            .fix-price {
              text-align: center;
              .buttons {
                margin-top: 1rem;
                display: flex;
                justify-content: center;
              }
            }
            .action-auction {
              padding-top: 24px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              @media screen and(max-width:576px) {
                flex-direction: column;
              }
            }
            .price-auction-box {
              width: 100%;
              .range_price {
                position: relative;
                display: flex;
                justify-content: space-between;
                border-top: 2px solid rgba($color: $colorWhite, $alpha: 0.3);
                width: 100%;
                padding-top: 10px;
                &::after {
                  position: absolute;
                  top: -4px;
                  right: 0px;
                  content: "";
                  width: 6px;
                  height: 6px;
                  background: $colorWhite;
                  border-radius: 50%;
                }
                &::before {
                  position: absolute;
                  top: -4px;
                  left: 0px;
                  content: "";
                  width: 6px;
                  height: 6px;
                  background: $colorWhite;
                  border-radius: 50%;
                }
              }
            }
          }

          .buttons {
            display: flex;
            justify-content: space-between;
            button {
              height: 40px;
              &:focus {
                outline: none;
                box-shadow: none;
              }
              &.btn-list,
              .btn-delist {
                margin-right: 0.3rem;
                width: 150px;
                border: 1px solid rgba(253, 220, 122, 0.7);
                border-radius: 6px;
                background-color: transparent;
                color: #fddc7a;
                font-size: 0.9rem;
                font-weight: bold;
              }

              &.btn-buy {
                // margin-left: 0.3rem;
                width: 150px;
                border: 1px solid rgba(253, 220, 122, 0.7);
                border-radius: 6px;
                background-color: $colorPrimary;
                color: #101b1e;
                font-size: 0.9rem;
                font-weight: bold;
              }
            }
          }
        }
      }
      .info-property {
        width: 100%;
        flex: 1.2 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &__general {
          flex: 2 1;
          background: linear-gradient(
              0deg,
              rgba(3, 9, 60, 0.3) 12.36%,
              rgba(19, 23, 55, 0) 100%
            ),
            rgba(0, 0, 0, 0.1);
          border: 1px solid #24232d;
          box-sizing: border-box;
          backdrop-filter: blur(12px);
          /* Note: backdrop-filter has minimal browser support */
          border-radius: 2px;
          border-top: 2px solid #0083fc;
          display: flex;
          justify-content: space-between;
          // padding: 1rem;

          .main-info {
            flex: 1 1;
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            padding: 0 0.5rem;

            .footballer-name {
              display: flex;
              flex-direction: column;
              align-items: center;

              .name {
                font-family: UTM Bebas;
                font-size: 2rem;
                line-height: 2;
                white-space: nowrap;
                letter-spacing: 0.02em;
                background: -webkit-linear-gradient(
                  180deg,
                  #ffffff 20.83%,
                  #c6c4c9 41.86%,
                  #ffffff 77.44%,
                  #aeabb2 100%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .id {
                background: #222540;
                border-radius: 2px;
                padding: 4px 8px;
                span {
                  opacity: 0.7;
                }
              }
            }
            .footballer-overall {
              margin-top: 1.5rem;
              font-family: "Poppins", sans-serif;
              background: -webkit-linear-gradient(
                180deg,
                #ffffff 20.83%,
                #c6c4c9 41.86%,
                #ffffff 77.44%,
                #aeabb2 100%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              .name {
                font-size: 1.25rem;
                line-height: 1.25;
                text-transform: uppercase;
              }

              .value {
                font-size: 3.25rem;
                line-height: 1;
                font-weight: 600;
                letter-spacing: 0.02em;
                text-align: center;
              }
            }

            ul {
              margin: unset;
              padding: unset;
              margin-top: 2rem;
              display: flex;
              li {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 1rem;

                .property {
                  opacity: 0.6;
                  font-family: "Poppins", sans-serif;
                  font-size: 0.8rem;
                  font-weight: 500;
                }

                .value {
                  font-size: 1rem;
                  line-height: 1.5;
                  font-weight: normal;
                  font-family: "Poppins", sans-serif;
                  white-space: nowrap;
                }
              }
            }
          }

          .property-info {
            list-style: none;
            text-transform: capitalize;
            padding: 0;
            margin: 0;
            li {
              display: flex;
              justify-content: flex-start;
              padding: 0.7rem;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              margin: 0 auto;
              &:last-child {
                border-bottom: none;
              }

              div:first-child {
                min-width: 150px;
              }
            }

            .property,
            .value {
              opacity: 0.6;
              font-family: "Poppins", sans-serif;
              font-size: 0.8rem;
              font-weight: 500;
            }
          }
        }

        &__talents {
          margin-top: 1rem;
          background: rgba(16, 25, 48, 0.27);
          border: 1px solid #24232d;
          box-sizing: border-box;
          backdrop-filter: blur(12px);
          border-radius: 2px;
          display: flex;
          align-items: flex-start;
          padding: 0 30px 6px;

          .talents-icon {
            margin-right: 40px;
          }

          .talent-list {
            width: 100%;
            justify-content: space-between;
            font-size: 12px;
            line-height: 24px;
            text-transform: capitalize;
            padding: 18px 0;

            .talent-item {
              margin-bottom: 2px;
              white-space: nowrap;
              cursor: default;
              .ant-tooltip-open {
                color: $colorPrimary;
              }
            }
          }
        }

        &__type {
          flex: 1 1;
          margin-top: 1rem;
          background: rgba(16, 25, 48, 0.27);
          border: 1px solid #24232d;
          box-sizing: border-box;
          backdrop-filter: blur(12px);
          /* Note: backdrop-filter has minimal browser support */
          border-radius: 2px;
          .tab-attribute {
            background: #000000;
            border-radius: 4px;
            margin: 0 auto;
            display: flex;
            white-space: nowrap;
            padding: 0;
            .tab-item {
              padding: 8px 20px;
              min-width: 200px;
              width: 50%;
              color: #fca702;
              text-align: center;
              cursor: pointer;
              line-height: 24px;
              transition: all 0.2s linear;
              @media screen and(max-width:576px) {
                min-width: 150px;
              }
              @media screen and(max-width:476px) {
                min-width: 100px;
              }
              @media screen and(max-width:340px) {
                padding: 6px 6px;
              }
              &.active {
                background: #fca702;
                border-radius: 4px;
                color: #000000;
                font-weight: bold;
              }
            }
          }

          ul {
            margin: unset;
            padding: unset;
          }

          .skills {
            list-style: none;
            display: flex;
            justify-content: space-between;
            padding: 17px;

            .skill.active {
              background: rgba(7, 41, 177, 0.5);
            }

            .skill {
              display: flex;
              flex-direction: column;
              align-items: center;
              background: rgba(15, 25, 65, 0.5);
              border-radius: 2px 0px 0px 2px;
              flex: 1 1;
              // margin: 1rem 0.4rem;
              padding: 1rem;

              img {
                width: 40px;
                height: 34px;
                margin-bottom: 1rem;
              }

              span {
                white-space: nowrap;
                font-family: "Poppins", sans-serif;
                font-size: 0.75rem;
                line-height: 1.5;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
              }
              div {
                white-space: nowrap;
                font-family: "Poppins", sans-serif;
                font-size: 1.25rem;
                line-height: 1.5;
                font-weight: bold;
              }
            }
          }

          // .properties {
          //   padding: 1rem 1rem 2rem 2rem;
          //   display: flex;
          //   flex-wrap: wrap;

          //   .property {
          //     flex: 0 0 21%;
          //     display: flex;
          //     font-size: 0.8rem;
          //     line-height: 2;
          //     margin: 0 0.5rem;

          //     .name {
          //       white-space: nowrap;
          //     }

          //     .value {
          //       margin-left: 0.3rem;
          //     }
          //   }
          // }
        }
      }
    }
  }

  .offter-history,
  .sale-history {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-family: UTM Bebas;
      font-weight: normal;
      font-size: 3rem;
      line-height: 3;
      color: #ffffff;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      button {
        height: 40px;

        &:first-child {
          margin-right: 0.3rem;
          width: 150px;
          border: 1px solid rgba(253, 220, 122, 0.7);
          border-radius: 6px;
          background-color: transparent;
          color: #fddc7a;
          font-size: 0.9rem;
          font-weight: bold;
        }

        &:last-child {
          margin-left: 0.3rem;
          width: 150px;
          border: 1px solid rgba(253, 220, 122, 0.7);
          border-radius: 6px;
          background-color: $colorPrimary;
          color: #101b1e;
          font-size: 0.9rem;
          font-weight: bold;
        }
      }
    }

    .offer-table,
    .sale-table {
      width: 100%;
      max-width: 1200px;
      background: rgba(16, 25, 48, 0.27);
      border: 1px solid #24232d;
      box-sizing: border-box;
      backdrop-filter: blur(12px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 2px;

      .rt-td {
        text-align: center;
      }

      .rt-thead {
        padding: 0.6rem;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        border: 1px solid #24232d;
        background: rgba(16, 25, 48, 0.27);

        .rt-tr {
          border: none;
          align-items: center;
        }

        .rt-th {
          box-shadow: none;
        }
      }

      .rt-tbody {
        overflow: hidden;

        .rt-tr-group:hover {
          background: #183047;
        }

        .rt-tr {
          align-items: center;
        }
      }

      .rt-tr {
        padding: 0.3rem;
        border-bottom: 1px solid #24232d;
      }

      .rt-table {
        &::-webkit-scrollbar {
          width: 1rem;
          height: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #888787;
          outline: 1px solid #24232d;
        }
      }
    }
  }
  .sale-history {
    &__events {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 0.5rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .detail-footballer {
    &__info {
      height: 100%;
      padding-top: 150px;
      background-size: 200% 50%;
    }
  }
}

@media (max-width: 700px) {
  .detail-footballer {
    .offter-history,
    .sale-history {
      .title {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 450px) {
  .detail-footballer {
    &__info {
      .info-wrapper {
        .info-property {
          &__talents {
            flex-wrap: wrap;

            .talents-icon {
              margin: auto;
            }

            .talent-list {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
