.vault {
  &-banner {
    background-image: url("../images/vault/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-top: 48.33%;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 992px) {
      padding-top: 62.83%;
      background-size: 130% 100%;
      background-position: center top;
      img {
        width: 160px;
      }
    }
    @media (max-width: 576px) {
      padding-top: 82.16%;
      background-size: 170% 100%;
      background-position: center top;
      img {
        width: 120px;
      }
    }
  }

  &-list {
    min-height: 500px;
    .container {
      position: relative;
      top: -80px;

      @media (max-width: 1280px) {
        top: -40px;
      }
      @media (max-width: 768px) {
        top: 0px;
      }
    }

    .row {
      justify-content: center;
      > div {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;
      }
    }
  }

  &-item {
    max-width: 254px;
    &__img {
      position: relative;
    }
    &__title {
      position: absolute;
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      font-family: Bebas Neue;
      font-size: 32px;
      line-height: 40px;
      text-shadow: 0px 0px 23px #ec8438;
    }
    &__bottom {
      .info-block {
        display: flex;
        justify-content: space-between;
        + .info-block {
          margin-top: 4px;
        }
      }
      .info-value {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $colorPrimary;
      }
    }
    &__info {
      padding: 16px 0;
    }
    &__action {
      text-align: center;
      button {
        background: $colorPrimary;
        color: $colorModalBg;
      }
    }
    &__desc {
      text-align: center;
      margin-top: 24px;
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
  }
}
