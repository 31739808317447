.the-countdown {
  // margin: 3rem 0;
  // height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-family: Fira Sans Condensed;
    font-style: italic;
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 100%;
    /* or 72px */

    text-align: center;
    text-transform: uppercase;

    /* Logo */

    color: #fcb100;
  }

  .subtitle {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  ul {
    list-style: none;
    display: flex;
    padding: unset;
    margin: unset;
    li {
      margin: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      span:first-child {
        font-family: "Bebas Neue", cursive;
        font-weight: normal;
        font-size: 7rem;
        text-shadow: -1px 1px 0px #ffffff, 0px 0px 23px #ec8438;
        // background: -webkit-linear-gradient(
        //   180deg,
        //   #ffffff 20.83%,
        //   #c6c4c9 41.86%,
        //   #ffffff 77.44%,
        //   #aeabb2 100%
        // );
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
      span:last-child {
        color: #fff;
        font-size: 1rem;
      }
      .dot {
        line-height: 150px;
      }
    }
  }

  .see-more {
    margin-top: 3rem;
    margin-bottom: 2rem;
    a {
      background: linear-gradient(13.71deg, #fca702 14.5%, #ffea32 127.09%);
      border-radius: 6px;
      padding: 0.8rem 1.5rem;
      transform: skewX(-10deg);
      border: none;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #fff;
    }
  }

  .video-responsive {
    margin-top: 2rem;
  }
  // .video-responsive {
  //   margin-top: 2rem;
  //   overflow: hidden;
  //   padding-bottom: 56.25%;
  //   position: relative;
  //   height: 0;
  //   width: 100%;
  // }

  // .video-responsive iframe {
  //   left: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  // }
}

@media (max-width: 992px) {
  .the-countdown {
    .video-responsive {
      margin-top: 2rem;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      width: 100%;
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

@media (max-width: 556px) {
  .the-countdown {
    margin-top: 2rem;
    .title {
      font-size: 1.5rem;
      text-align: center;
    }
    .subtitle {
      font-size: 1rem;
      text-align: center;
    }
    ul {
      li {
        span:first-child {
          font-size: 3.5rem;
        }
        span:last-child {
          color: #aeaeae;
          font-size: 0.7rem;
        }
        .dot {
          line-height: 80px;
        }
      }
    }
  }
}
